import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import { addParentPathToChildPaths } from "src/routes/internalHelpers";
import { type Route } from "src/routes/types";

// #region Types

/**
 * List of all route keys for careFormsChildrenRoutes as an enum object
 */
export const CareFormsRouteNames = {
  careForms: "careForms",
} as const;

/**
 * Type of the keys of all of the routes for careFormsChildrenRoutes (e.g. to ensure that all routes are covered for display)
 */
export type CareFormsRouteName =
  (typeof CareFormsRouteNames)[keyof typeof CareFormsRouteNames];

// #endregion

// #region Constants

/**
 * The parent route for all care forms routes
 */
export const CARE_FORMS_ROUTE = "/forms";

/**
 * All route metadata for all children routes of care forms without the care forms route prepended
 */
const CARE_FORMS_PARTIAL_ROUTES: {
  [key in CareFormsRouteName]: Route;
} = {
  // Full route: /${CARE_FORMS_ROUTE}/
  [CareFormsRouteNames.careForms]: {
    path: "/",
    availableOnMobile: true,
    isValidHomepage: false,
    requiredResourcePermissions: [[resourceKeys.forms]],
    name: "Care Forms",
  },
} as const;

// #endregion

// #region Exportdashboard/consults

/**
 * Create new object from careFormsRoutes with /${CARE_FORMS_ROUTE}/ prepended to each path
 */
export const careFormsChildrenRoutes: {
  [key in CareFormsRouteName]: Route;
} = addParentPathToChildPaths(
  CARE_FORMS_PARTIAL_ROUTES,
  CareFormsRouteNames,
  CARE_FORMS_ROUTE,
);

// #endregion

export default careFormsChildrenRoutes;
