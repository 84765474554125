import { ProcessStatus } from "@alethea-medical/alethea-components";
import { Slide } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { set } from "date-fns";
import { FC, useEffect, useState } from "react";
import { useContext } from "react";
import { Control } from "react-hook-form";
import OneTimeTooltip from "src/components/OneTimeTooltip";
import SnackbarMessage from "src/components/SnackbarMessage";
import { AuthContext } from "../../../AuthProvider";
import LeavePagePrompt from "../../../components/LeavePagePrompt";
import { getDefaultClinicIndex } from "../../../utils/locationUtils";
import ConsultAttachments from "./Attachments/ConsultAttachments";
import AvaEconsultEndpoint from "./Ava/AvaEconsultEndpoint";
import ConsultFormCard from "./ConsultFormCard";
import ConsultFormController from "./ConsultFormController";
import ConsultLocationSelector from "./ConsultLocationSelector";
import ConsultDraftButtons from "./Draft/ConsultDraftButtons";
import ConsultDraftController from "./Draft/ConsultDraftController";
import ConsultDraftSubject from "./Draft/ConsultDraftSubject";
import ConsultGallery from "./Gallery/ConsultGallery";
import ConsultGalleryPreviewer from "./Gallery/ConsultGalleryPreviewer";
import ConsultPatientInfoForm from "./PatientInfoForm/ConsultPatientInfoForm";
import ApproverSearchBar from "./SearchAndResults/Approver/ApproverSearchBar";
import { PermissionsProvider } from "./SearchAndResults/PermissionsContext";
import ServiceSearchContainer from "./SearchAndResults/ServiceSearchContainer";
import { ServiceSearchControl } from "./SearchAndResults/ServiceSearchController";
import ServiceFormContainer from "./ServiceForm/ServiceFormContainer";
import useConsultFormStyles from "./useConsultFormStyles";

const ConsultContainer: FC = () => {
  const { classes, cx } = useConsultFormStyles();
  const authContext = useContext(AuthContext);
  const imageWarningLimit = 8;

  const {
    handleSubmit,
    draftId,
    setDraftId,
    // State
    isDisabled,
    processState,
    processErrorMessage,
    loadingMessage,
    hideForm,
    selectedService,
    // Gallery
    showGallery,
    selectedMedia,
    selectHandler,
    selectMultipleHandler,
    unselectHandler,
    unselectAllHandler,
    files,
    handleUploadFilesWithPreview,
    // React hook form
    control,
    getValues,
    trigger,
    unregister,
    isDirty,
    cleanForm,
    clearForm,
    setValue,
    watch,
    // Dropzone
    createFileList,
    createFileThumbs,
    createDropzone,
    createUploadFromClipboardButton,
    // EMR Form Fields
    emrFormFields,
    // Handlers
    handleSelectLocation,
    handleSelectService,
    handleDeselectService,
    handleClearSpecialtyAndSubsite,
    handleSelectPatient,
    handleAddEmrMedicalHistory,
    handleEmrLink,
    handleOpenGallery,
    handleCloseGallery,
    handleResetSubsite,
    handleChangeSearchType,
    handleUpdateSymptomField,
    selectedApproverUid,
    handleSelectApproverUid,
    handleDeselectApproverUid,
    sendEconsultButtonVisible,
    forwardEconsultButtonVisible,
    forwardButtonEnabled,
    handleForwardEconsult,
    econsultForwarder,
    setEconsultForwarder,
  } = ConsultFormController(
    getDefaultClinicIndex(authContext.profile?.locations ?? []), // Get default clinic index, pass it to the controller so default clinic can be set correctly
  );

  const {
    saveDraftHandler,
    discardDraftHandler,
    draftSubject,
    setDraftSubject,
    draftState,
    draftError,
    setDraftState,
    draftLoadingMessage,
    draftSuccessMessage,
    isLoadingDraft,
  } = ConsultDraftController({
    draftId,
    setDraftId,
    cleanForm,
    clearForm,
    getValues,
    selectService: handleSelectService,
    selectedMedia,
    files,
    selectMultipleHandler,
    handleUploadFilesWithPreview,
    econsultForwarder,
    setEconsultForwarder,
  });

  const [galleryAnchor, setGalleryAnchor] = useState<HTMLElement | null>(null);
  const [showForwardButton, setShowForwardButton] = useState<boolean>(false);
  const [disableSendButton, setDisableSendButton] = useState<boolean>(false);
  const [isDirtyFlag, setIsDirtyFlag] = useState<boolean>(isDirty);
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    forwardEconsultButtonVisible.then((visible) => {
      setShowForwardButton(visible);
    });

    sendEconsultButtonVisible.then((visible) => {
      setDisableSendButton(!visible);
    });
  }, [
    forwardEconsultButtonVisible,
    sendEconsultButtonVisible,
    setShowForwardButton,
    setDisableSendButton,
  ]);

  // Show the snackbar message if the user has signed up for Alethea Premium
  // We use query params to determine if the user has signed up for Alethea Premium
  // Current Stripe redirect to http://localhost:5173/dashboard/consult?payment_intent=pi_3QxYRMGOgMTCu5So0VHX6EJp&payment_intent_client_secret=pi_3QxYRMGOgMTCu5So0VHX6EJp_secret_fkPymJZoIty4pAwcViHTM1Shs&redirect_status=succeeded
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const paymentIntent = queryParams.get("payment_intent");
    const paymentIntentClientSecret = queryParams.get(
      "payment_intent_client_secret",
    );
    const redirectStatus = queryParams.get("redirect_status");
    if (
      paymentIntent &&
      paymentIntentClientSecret &&
      redirectStatus === "succeeded"
    ) {
      setShowSnackbar(true);
    }
  }, []);

  // We should really be using the isDirty flag from the controller, but it's not updating correctly (see the handleSubmitWrapper function)
  useEffect(() => {
    setIsDirtyFlag(isDirty);
  }, [isDirty]);

  // Only let
  const handleSubmitWrapper = (event: any) => {
    if (!selectedService) {
      // Don't let form submission unless a service is selected
      event.preventDefault(); // Prevent page from reloading
      return false;
    }

    // Manually set the isDirty flag to false
    // TODO: Investigate why the isDirty flag is being set to true in the middle of the form submission
    setIsDirtyFlag(false);

    handleSubmit(event);
  };

  return (
    <PermissionsProvider>
      {/* Render centered container with max width */}
      <Container maxWidth={"md"} className={classes.container}>
        <div
          className={cx({
            [classes.displayNone]: !showGallery,
          })}
        >
          <Slide direction="left" in={showGallery} mountOnEnter>
            <div>
              <ConsultGallery
                selectedMedia={selectedMedia}
                selectHandler={selectHandler}
                unselectHandler={unselectHandler}
                unselectAllHandler={unselectAllHandler}
                onCloseGallery={handleCloseGallery}
                imageWarningLimit={imageWarningLimit}
              />
            </div>
          </Slide>
        </div>

        <div
          className={cx(
            {
              [classes.displayNone]: showGallery,
            },
            classes.form,
          )}
        >
          <form
            onSubmit={handleSubmitWrapper}
            autoComplete="off"
            aria-autocomplete="none"
          >
            {/* Draft state, buttons, subject display, and subject input */}
            {/* Draft loading state */}
            <ProcessStatus
              state={draftState}
              errorMessage={draftError}
              setState={setDraftState}
              useSnackbar
              hideProgressBar
              loadingMessage={draftLoadingMessage}
              successMessage={draftSuccessMessage}
            />
            <ConsultDraftButtons
              control={control}
              draftSubject={draftSubject}
              setDraftSubject={setDraftSubject}
              saveDraftHandler={saveDraftHandler}
              discardDraftHandler={discardDraftHandler}
              disabled={isDisabled || isLoadingDraft}
              draftId={draftId}
              isDirty={isDirtyFlag}
            />
            {/* Only show subject if a draft is loaded (draftId is defined) */}
            {draftId && <ConsultDraftSubject draftSubject={draftSubject} />}

            {!isLoadingDraft && (
              <>
                {econsultForwarder && (
                  <ConsultFormCard
                    className={classes.spacing}
                    style={{ backgroundColor: "#e3f3ef" }}
                  >
                    <h3 style={{ color: "#05707d", marginBottom: 0 }}>
                      {" "}
                      This eConsult was forwarded, currently pending approval by
                      you.
                    </h3>
                    <p style={{ color: "#05707d", marginTop: 0 }}>
                      Sent by {econsultForwarder.firstName}{" "}
                      {econsultForwarder.lastName} ({econsultForwarder.email})
                    </p>
                  </ConsultFormCard>
                )}

                {/* Choose referring clinic dropdown */}
                <ConsultFormCard className={classes.spacing}>
                  <ConsultLocationSelector
                    control={control}
                    disabled={isDisabled}
                    onSelectLocation={handleSelectLocation}
                    setValue={setValue}
                  />
                </ConsultFormCard>

                {/* Search bar and search results */}
                <ConsultFormCard className={classes.spacing}>
                  <ServiceSearchContainer
                    selectedService={selectedService}
                    selectService={handleSelectService}
                    clearSpecialtyAndSubsite={handleClearSpecialtyAndSubsite}
                    changeSearchType={handleChangeSearchType}
                    deselectService={handleDeselectService}
                    control={
                      control as unknown as Control<ServiceSearchControl>
                    }
                    resetSubsite={handleResetSubsite}
                  />
                </ConsultFormCard>

                <AvaEconsultEndpoint
                  control={control}
                  onAddEmrMedicalHistory={handleAddEmrMedicalHistory}
                  onEmrPatientInfo={handleSelectPatient}
                  onEmrLink={handleEmrLink}
                />

                <div
                  className={cx({
                    [classes.displayNone]: hideForm,
                  })}
                >
                  <OneTimeTooltip
                    visible={!hideForm && !showGallery}
                    onScreenAnchor={galleryAnchor}
                    tooltipName="gallery_moved_dec_19_2023"
                    title='The gallery has moved. Click "Open Gallery" to attach images to your consult.'
                    placement="top"
                  >
                    {/* Patient info form, with OOP input */}
                    <ConsultFormCard
                      title="Patient Information"
                      className={classes.spacing}
                    >
                      <ConsultPatientInfoForm
                        control={control}
                        trigger={trigger}
                        setValue={setValue}
                        watch={watch}
                        disabled={isDisabled}
                        onSelectPatient={handleSelectPatient}
                        serviceProvince={
                          selectedService?.service.clinic.location.province
                        }
                      />
                    </ConsultFormCard>
                  </OneTimeTooltip>

                  <div className={classes.spacing}>
                    <ServiceFormContainer
                      control={control}
                      emrFormFields={emrFormFields}
                      unregister={unregister}
                      updateSymptomField={handleUpdateSymptomField}
                      disabled={isDisabled}
                    />
                  </div>

                  {/* Gallery */}
                  <div ref={setGalleryAnchor}>
                    <ConsultFormCard
                      title="Gallery Attachments"
                      className={classes.spacing}
                    >
                      <ConsultGalleryPreviewer
                        selectedMedia={selectedMedia}
                        selectHandler={selectHandler}
                        unselectHandler={unselectHandler}
                        unselectAllHandler={unselectAllHandler}
                        onOpenGallery={handleOpenGallery}
                        imageWarningLimit={imageWarningLimit}
                      />
                    </ConsultFormCard>
                  </div>

                  {/* Attachments */}
                  <ConsultFormCard
                    title="Other Attachments"
                    className={classes.spacing}
                  >
                    <ConsultAttachments
                      createUploadFromClipboardButton={
                        createUploadFromClipboardButton
                      }
                      createDropzone={createDropzone}
                      createFileList={createFileList}
                      createFileThumbs={createFileThumbs}
                    />
                  </ConsultFormCard>

                  {/* If forward_econsult, should have search bar to select approver */}
                  {showForwardButton && (
                    <ConsultFormCard
                      title="Select Approver"
                      className={classes.spacing}
                    >
                      <ApproverSearchBar
                        selectedApproverUid={selectedApproverUid}
                        selectApproverUid={handleSelectApproverUid}
                        deselectApproverUid={handleDeselectApproverUid}
                      />
                    </ConsultFormCard>
                  )}

                  <div className={classes.spacing}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isDisabled || disableSendButton}
                    >
                      Submit
                    </Button>

                    {showForwardButton && (
                      <Button
                        className={classes.leftMargin}
                        variant="contained"
                        color="primary"
                        disabled={!forwardButtonEnabled}
                        onClick={handleForwardEconsult}
                      >
                        Forward
                      </Button>
                    )}
                  </div>
                </div>
              </>
            )}
          </form>
          {/* Submit */}
          <div className={classes.spacing}>
            <ProcessStatus
              state={processState}
              errorMessage={processErrorMessage}
              successMessage="eConsult Sent Successfully"
              loadingMessage={loadingMessage}
            />
          </div>
        </div>
        <LeavePagePrompt
          isDirty={isDirtyFlag}
          message={
            "You have unsaved changes, are you sure you want to leave? You can save your changes by pressing Save Draft."
          }
        />
        <SnackbarMessage
          message={
            <span>
              You have successfully signed up for Alethea Premium!! 🎉 Your
              premium features will be available in a minute, after payment is
              finished processing. If you have any questions, please contact{" "}
              <a href="mailto:sales@aletheamedical.com">
                sales@aletheamedical.com
              </a>
              .
            </span>
          }
          show={showSnackbar}
          setShow={setShowSnackbar}
        />
      </Container>
    </PermissionsProvider>
  );
};

export default ConsultContainer;
