import { Box, SxProps } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";

export enum ProcessState {
  idle = 0,
  running = 1,
  success = 2,
  error = 3,
  warning = 4,
}

interface ProcessStatusProps {
  className?: string;
  state: ProcessState;
  setState?: (state: ProcessState) => void;
  loadingMessage?: string;
  warningMessage?: string;
  errorMessage?: string;
  successMessage?: string;
  useSnackbar?: boolean;
  hideProgressBar?: boolean;
  sx?: SxProps;
}

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const ProcessStatus = ({
  className,
  state,
  setState,
  loadingMessage,
  errorMessage,
  warningMessage,
  successMessage,
  useSnackbar,
  hideProgressBar,
  sx,
}: ProcessStatusProps) => {
  const getLoadingComponent = () => {
    if (loadingMessage !== undefined) {
      if (useSnackbar)
        return (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={state === ProcessState.running}
          >
            <Alert severity="info">{loadingMessage}</Alert>
          </Snackbar>
        );
      else return <MuiAlert severity="info">{loadingMessage}</MuiAlert>;
    }
  };

  const getSuccessComponent = () => {
    if (successMessage !== undefined) {
      if (useSnackbar)
        return (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={state === ProcessState.success}
          >
            <Alert
              severity="success"
              onClose={() => {
                if (setState) setState(ProcessState.idle);
              }}
            >
              {successMessage}
            </Alert>
          </Snackbar>
        );
      else return <MuiAlert severity="success">{successMessage}</MuiAlert>;
    }
  };

  const getErrorComponent = () => {
    if (errorMessage !== undefined) {
      if (useSnackbar)
        return (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={state === ProcessState.error}
          >
            <Alert
              severity="error"
              onClose={() => {
                if (setState) setState(ProcessState.idle);
              }}
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        );
      else return <MuiAlert severity="error">{errorMessage}</MuiAlert>;
    }
  };

  const getWarningComponent = () => {
    if (warningMessage !== undefined) {
      if (useSnackbar)
        return (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={state === ProcessState.warning}
          >
            <Alert
              severity="warning"
              onClose={() => {
                if (setState) setState(ProcessState.idle);
              }}
            >
              {warningMessage}
            </Alert>
          </Snackbar>
        );
      else return <MuiAlert severity="warning">{warningMessage}</MuiAlert>;
    }
  };

  return (
    <Box className={className} sx={sx}>
      {state === ProcessState.success && getSuccessComponent()}
      {state === ProcessState.error && getErrorComponent()}
      {state === ProcessState.warning && getWarningComponent()}
      {state === ProcessState.running && (
        <>
          {hideProgressBar !== true && <LinearProgress />}
          {getLoadingComponent()}
        </>
      )}
    </Box>
  );
};
