import {
  Service,
  SpecialistAvailability,
} from "@alethea-medical/aletheamd-types";
import {
  addDays,
  compareAsc,
  differenceInCalendarDays,
  endOfDay,
  endOfToday,
  endOfYesterday,
  format,
  getDay,
  isToday,
  max,
  startOfDay,
  subDays,
} from "date-fns";

// Helper to read date for debugging purposes
const readDate = (date: Date) => {
  return format(date, "yyyy-MM-dd HH:mm:ss");
};

export interface ServiceItem {
  id: string;
  service: Service.Service;
}

// #region Service Helpers
// -------------------------

function join(
  str1: string | undefined,
  str2: string | undefined,
  separator: string,
) {
  const builder: string[] = [];
  if (str1) builder.push(str1);
  if (str2) builder.push(str2);
  return builder.join(separator);
}

export const getFormattedClinicCityProvinceFromService = (
  service: Service.Service,
): string => {
  const l = service?.clinic?.location;
  if (!l) return "";

  return join(join(l.city, l.division, " "), l.province, ", ");
};

export const getFormattedClinicAddressFromService = (
  service: Service.Service,
): string => {
  const l = service?.clinic?.location;
  if (!l) return "";

  return join(
    join(join(l.street, l.city, ", "), l.province, ", "),
    l.postalCode,
    ", ",
  );
};

export const getFormattedUserNameWithCredsFromServiceUser = (
  user?: Service.ServiceUser,
): string => {
  if (!user) return "";

  return join(
    join(user.salutation, user.userName, " "),
    getTitleAndCredsFromServiceUser(user),
    ", ",
  );
};

export const getTitleAndCredsFromServiceUser = (
  user?: Service.ServiceUser,
): string => {
  if (!user) return "";

  return join(user.title, user.credentials, " | ");
};

// #endregion ==================================================

// #region Availability Helpers
// -----------------------------

/**
 * Finds the end date of the latest vacation that the service is currently on
 * returns endDate of the vacation period
 * @param service Service to check vacation for
 * @returns Timestamp of end date for vacation
 */
const getVacationEndDate = (
  service: Service.Service,
  startDate: Date,
): Date => {
  return service.vacations.reduce(
    (accumulator: Date, vacation: Service.ServiceVacation): Date => {
      const vacationStartDate = vacation.start.toDate();
      const vacationEndDate = vacation.end.toDate();
      if (
        differenceInCalendarDays(vacationStartDate, startDate) <= 0 &&
        differenceInCalendarDays(vacationEndDate, startDate) >= 0 &&
        differenceInCalendarDays(vacationEndDate, accumulator) >= 0 // usually vacationEndDate comes later than accumulator when they are the same day
        // ex. vacationEndDate = 2025-03-23 23:59:59 accumulator = 2025-03-23 00:00:00  result = +0
      ) {
        return endOfDay(vacationEndDate);
      }
      return accumulator;
    },
    startDate,
  );
};

const LimitEndDates: {
  [key in SpecialistAvailability.Period]: Date;
} = {
  daily: endOfToday(),
  // weekly: endOfDay(endOfISOWeek(today)),
  // monthly: endofDay(endOfMonth(today)),
};

/**
 * Returns the end date of the longest maxed out limits period. If no limits are maxed out, returns end of yesterday
 *
 * NOTE: we check the longest maxed out period because you can't have a higher limit than the longest period
 * e.g. 100 daily, 10 monthly. You can't have 11 consults daily because you're maxed out on monthly
 * @param consultLimits Consult limits to check
 * @param userConsultsCount Number of consults the user has sent so far for the longest period
 * @returns Date of end date of the limits period
 */
const getLimitsEndDate = (
  consultLimits: SpecialistAvailability.ConsultLimits | undefined,
  userConsultsCount: number,
): Date => {
  if (!consultLimits) return endOfYesterday();
  return Object.entries(consultLimits).reduce(
    (farthestEndDate, [period, limits]) => {
      if (
        limits.enabled &&
        userConsultsCount >= limits.limit &&
        period in LimitEndDates
      ) {
        return max([
          LimitEndDates[period as keyof typeof LimitEndDates],
          farthestEndDate,
        ]);
      }
      return farthestEndDate;
    },
    endOfYesterday(),
  );
};

// TODO make sure that we update the options to prevent all seven days from being selected as unavailable

/**
 * Returns the end unavailable date of the custom calendar period, starting from the start date
 * If all days are unavailable, returns the start date (We do this, because user should be on vacation instead of marking all days unavailable)
 * User is prevented from marking all days unavailable in the UI, but this is a safety check
 * @param customCalendar Type for custom calendar in the specialist_availability_settings collection
 * @param startDate Starting date Start of the checking date
 * @returns Date of end date of the limits period
 */
const getCustomCalendarEndDate = (
  customCalendar: SpecialistAvailability.CustomCalendar | undefined,
  startDate: Date,
): Date => {
  if (!customCalendar) {
    return endOfDay(subDays(startDate, 1));
  }
  for (
    let currentDate = startDate;
    currentDate <= addDays(startDate, 7);
    currentDate = addDays(currentDate, 1)
  ) {
    const day = getDay(currentDate);
    const CustomCalendarDays = SpecialistAvailability.CustomCalendarDays;
    if (
      customCalendar[CustomCalendarDays[day as keyof typeof CustomCalendarDays]]
    ) {
      return endOfDay(subDays(currentDate, 1));
    }
  }
  return endOfDay(subDays(startDate, 1));
};

/**
 * Returns the end date when the services are unavailable, checking for vacation or custom calendar restrictions
 * Do not need to check for when the next limit refreshes, because when the next period starts, the user will be able to send consults again
 * @param service
 * @param endOfPreviousUnavailability end date of the previous unavailability
 */
const getCustomCalendarAndVacationEndDay = (
  service: Service.Service,
  endOfPreviousUnavailability: Date,
) => {
  const checkDate = startOfDay(addDays(endOfPreviousUnavailability, 1));
  const customCalendarEndDate = getCustomCalendarEndDate(
    service?.customCalendar,
    checkDate,
  );
  const vacationEndDate = getVacationEndDate(service, checkDate);
  const endDate = max([customCalendarEndDate, vacationEndDate]);
  if (compareAsc(endDate, checkDate) < 1) return checkDate;
  return getCustomCalendarAndVacationEndDay(service, endDate);
};

/**
 * Returns the next day that a service is still unavailable if it is currently unavailable,
 * otherwise returns undefined
 *
 * NOTE: We check limits first before the recursion because the user will have their limits reset after this consult limit period, meaning we only need to check it once.
 * @param service
 * @returns
 */
export const getNextAvailableDate = (
  service: Service.Service,
): Date | undefined => {
  const limitsEndDate = getLimitsEndDate(
    service?.consultLimits,
    service?.consultLimits?.daily.count || -1,
  );
  const endDate = getCustomCalendarAndVacationEndDay(service, limitsEndDate);
  return isToday(endDate) ? undefined : endDate;
};

// Checks if the provider of the service (i.e. Specialist) is currently on unavailable
// Service will be unavailable if the provider is currently: on vacation, blocked on their custom schedule, or maxed out on consult limits
export const isServiceUnavailable = (service: Service.Service): boolean => {
  return getNextAvailableDate(service) !== undefined;
};

// #endregion ==================================================
