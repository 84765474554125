import {
  PaperPage,
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import Send from "@mui/icons-material/Send";
import {
  Button,
  Grid2 as Grid,
  InputAdornment,
  InputLabel,
  Tooltip,
} from "@mui/material";
import FormData from "form-data";
import React, { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { PatientInfo } from "src/../shared/types";
import { AuthContext } from "src/AuthProvider";
import analyticsLogs from "src/analyticsLogs";
import DisplayName from "src/components/DisplayNameInForm";
import EmailInput from "src/components/FormInputFields/EmailInput";
import FormCheckbox from "src/components/FormInputFields/FormCheckbox";
import FormTextArea from "src/components/FormInputFields/FormTextArea";
import FormTextField from "src/components/FormInputFields/FormTextField";
import PHNInputLookup from "src/components/FormInputFields/PHNInput";
import HelpModal from "src/components/HelpModal";
import LeavePagePrompt from "src/components/LeavePagePrompt";
import LocationDropdown from "src/components/LocationDropdown";
import SelectSendOnBehalfOf from "src/components/SelectSendOnBehalfOf";
import { SendingOnBehalfOfOption } from "src/components/SelectSendOnBehalfOf/SelectSendOnBehalfOf";
import SuccessModal from "src/components/SuccessModal";
import useFileDrop from "src/components/useFileDrop";
import usePermissions from "src/components/usePermissions";
import { fbFunctions, logAnalyticsEvent } from "src/firebase";
import buildSignoff from "src/models/buildSignoff";
import isMobileDevice from "src/models/isMobileDevice";
import serverRequest from "src/models/serverRequest";

const canSendPremiumOneWayMessages = async (
  numUses?: number,
): Promise<boolean> => {
  try {
    const { data } = await fbFunctions.httpsCallable(
      "premium-canSendOneWayMessages",
    )({
      numUses,
    });
    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const sendPremiumOneWayMessage = async (numUses?: number): Promise<boolean> => {
  try {
    const { data } = await fbFunctions.httpsCallable(
      "premium-sendOneWayMessages",
    )({
      numUses,
    });
    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const getNumberOfPremiumOneWayMessageUsesRemaining = async (): Promise<
  number | string
> => {
  try {
    const { data } = await fbFunctions.httpsCallable(
      "premium-getNumberOfOneWayMessageUsesRemaining",
    )();
    return data;
  } catch (e) {
    console.error(e);
    return 0;
  }
};

const OneWayMessaging: FC = () => {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { dirtyFields, isDirty },
  } = useForm({ mode: "onTouched" });
  const locationIdx = useWatch({
    control,
    name: "locationIdx",
    defaultValue: 0,
  });

  const [premiumUsesRemaining, setPremiumUsesRemaining] = React.useState<
    number | string
  >("loading...");
  const { granted: isPremiumOneWayMessageSender } = usePermissions({
    resourceKey: resourceKeys.premiumOneWayMessaging,
  });
  const [
    canPremiumUserSendOneWayMessages,
    setCanPremiumUserSendOneWayMessages,
  ] = React.useState(false);

  const isPremiumAndUnableToSend = useMemo(
    () => isPremiumOneWayMessageSender && !canPremiumUserSendOneWayMessages,
    [isPremiumOneWayMessageSender, canPremiumUserSendOneWayMessages],
  );

  const onSendPremiumOneWayMessage = useCallback(async () => {
    await sendPremiumOneWayMessage();
    // Use this instead of getting from server to reduce firebase costs
    setPremiumUsesRemaining((prev) => {
      if (typeof prev === "number") return prev - 1;
      return prev;
    });
    // Need to check if user can send more messages
    const maySend = await canSendPremiumOneWayMessages();
    setCanPremiumUserSendOneWayMessages(maySend);
  }, [
    setPremiumUsesRemaining,
    sendPremiumOneWayMessage,
    canSendPremiumOneWayMessages,
    setCanPremiumUserSendOneWayMessages,
  ]);

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  const isDisabled = () => {
    return (
      processState === ProcessState.running ||
      processState === ProcessState.success ||
      isPremiumAndUnableToSend
    );
  };

  useEffect(() => {
    setValue("locationIdx", getValues("locationIdx")); // Set locationIdx for default clinic
  }, [locationIdx]);

  const {
    files,
    resetFiles,
    createFileList,
    createFileThumbs,
    createDropzone,
  } = useFileDrop({
    disabled: isDisabled(),
  });

  const authContext = useContext(AuthContext);

  const { granted: sendMsgOnBehalfOfPhysicianOrClinic } = usePermissions({
    resourceKey: resourceKeys.sendMsgOnBehalfOfPhysicianOrClinic,
  });

  const [optionMustBeChosen, setOptionMustBeChosen] = React.useState(true);

  const sendingOnBehalfOfOption = useWatch({
    control,
    name: "sendingOnBehalfOfOption",
  });

  const displayName = useWatch({
    control,
    name: "displayName",
  });

  useEffect(() => {
    setOptionMustBeChosen(
      sendMsgOnBehalfOfPhysicianOrClinic && !sendingOnBehalfOfOption,
    ); // If MOA can, MOA MUST choose someone
  }, [sendMsgOnBehalfOfPhysicianOrClinic, sendingOnBehalfOfOption]);

  // Sync display name with sending on behalf of name
  useEffect(() => {
    const prefix =
      sendingOnBehalfOfOption?.isClinic || !sendingOnBehalfOfOption
        ? ""
        : "Dr. ";
    const displayName = sendingOnBehalfOfOption?.label ?? "";
    setValue("displayName", `${prefix}${displayName}`, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [sendingOnBehalfOfOption, getValues, setValue]);

  // Check if a premium one way messaging user has remaining messages
  useEffect(() => {
    const updateCanPremiumUserSendOneWayMessages = async () => {
      if (!isPremiumOneWayMessageSender) {
        return;
      }
      setProcessState(ProcessState.running);

      const [maySendMessages, numberOfRemainingMessages] = await Promise.all([
        await canSendPremiumOneWayMessages(),
        await getNumberOfPremiumOneWayMessageUsesRemaining(),
      ]);
      setCanPremiumUserSendOneWayMessages(maySendMessages);
      setPremiumUsesRemaining(numberOfRemainingMessages);

      setProcessState(ProcessState.idle);
    };
    updateCanPremiumUserSendOneWayMessages();
  }, [
    setCanPremiumUserSendOneWayMessages,
    isPremiumOneWayMessageSender,
    setProcessState,
    setPremiumUsesRemaining,
    ProcessState,
  ]);

  const getSubjectPrepend = (
    option: SendingOnBehalfOfOption,
    displayName?: string,
  ): string => {
    let subjectPrepend = "Secure Message from ";
    if (sendMsgOnBehalfOfPhysicianOrClinic) {
      // If they are an MOA with permission to send on behalf of someone else
      if (displayName)
        subjectPrepend += `${displayName}: `; // display name if available
      else if (option)
        subjectPrepend += `${option.label}: `; // If option chosen, go with label
      else
        subjectPrepend += `${authContext?.profile?.locations[locationIdx].clinicName}: `; // If option NOT chosen, default to clinic
    } else subjectPrepend += `Dr. ${authContext?.profile?.lastName}: `;

    return subjectPrepend;
  };

  const onSubmit = (data: any) => {
    setProcessState(ProcessState.running);
    logAnalyticsEvent(analyticsLogs.oneWayMessaging.start);

    if (isPremiumAndUnableToSend) {
      errorHandler({
        userMessage: "You have reached your limit of one-way messages.",
        analyticsLog:
          analyticsLogs.premium.premiumUsage.oneWayMessage.maxReached,
      });
      return;
    }

    const form = new FormData();
    form.append("physicianUid", authContext.uid);
    form.append("locationIdx", data.locationIdx);
    form.append("patientEmail", data.email);
    form.append("phn", data.phn);
    form.append(
      "subject",
      getSubjectPrepend(data?.sendingOnBehalfOfOption, data.displayName) +
        data.subject,
    );
    form.append("body", `${data.body}\n\n${data.signOff}`);
    form.append("delay", data.delaySend);
    if (data.sendingOnBehalfOfOption) {
      form.append(
        "sendingOnBehalfOfOptionUid",
        data.sendingOnBehalfOfOption.id,
      );
      form.append(
        "sendingOnBehalfOfOptionKind",
        data.sendingOnBehalfOfOption.isClinic ? "clinic" : "physician",
      );
      form.append("sendingOnBehalfOfOptionDisplayName", data.displayName);
    }
    Object.values(files).forEach((file) => {
      form.append("attachment", file.file, file.filename);
    });

    serverRequest(authContext.user, {}, form, "one-way-patient-message")
      .then(async () => {
        if (isPremiumOneWayMessageSender)
          logAnalyticsEvent(
            analyticsLogs.premium.premiumUsage.oneWayMessage.success,
          );
        else logAnalyticsEvent(analyticsLogs.oneWayMessaging.success);
        // Tell backend the message is sent by a premium user
        if (isPremiumOneWayMessageSender) {
          await onSendPremiumOneWayMessage();
        }
        resetForm();
        setProcessState(ProcessState.success);
        setTimeout(() => {
          setProcessState(ProcessState.idle);
        }, 1000);
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error sending one way patient message",
          analyticsLog: isPremiumOneWayMessageSender
            ? analyticsLogs.premium.premiumUsage.oneWayMessage.fail
            : "one_way_patient_email_failed",
        });
      });
  };

  const resetForm = () => {
    reset({
      locationIdx: getValues("locationIdx"),
      delaySend: false,
      phn: "",
      email: "",
      subject: "",
      body: "",
      signOff: getValues("signOff"),
      sendingOnBehalfOfOption: getValues("sendingOnBehalfOfOption"),
    });
    resetFiles();
  };

  const onError = () => {
    errorHandler({
      userMessage: "Check form for errors.",
    });
  };

  useEffect(() => {
    if (dirtyFields.signOff !== true) {
      const nameObject: {
        first: string | undefined;
        last: string | undefined;
      } = { first: undefined, last: undefined };
      if (sendingOnBehalfOfOption) {
        if (!sendingOnBehalfOfOption.isClinic) {
          nameObject.first = sendingOnBehalfOfOption.firstName;
          nameObject.last = sendingOnBehalfOfOption.lastName;
        }
        // Otherwise default to no name (clinic)
      } else {
        nameObject.first = authContext.profile?.firstName;
        nameObject.last = authContext.profile?.lastName;
      }

      setValue(
        "signOff",
        buildSignoff(nameObject, authContext?.profile?.locations[locationIdx]),
      );
    }
  }, [locationIdx, sendingOnBehalfOfOption]);

  return (
    <>
      <PaperPage enablePadding>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          autoComplete="off"
          aria-autocomplete="none"
        >
          <fieldset disabled={isDisabled()} aria-autocomplete="none">
            <Grid container spacing={1}>
              <Grid size={{ xs: 12 }}>
                <HelpModal
                  buttonText="About One-Way Messaging"
                  helpText={[
                    "Your message is securely sent to the patient through the alethea.care portal.",
                    "If delay is checked, your message is sent the next day at 7:00 AM MST.",
                  ]}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Grid container spacing={1}>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <LocationDropdown
                      name="locationIdx"
                      control={control}
                      label="Clinic"
                      disabled={isDisabled()}
                      setValue={setValue}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <SelectSendOnBehalfOf control={control} />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <DisplayName control={control} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Grid container spacing={1}>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <PHNInputLookup
                      name="phn"
                      control={control}
                      label="PHN"
                      required
                      handlePatientInfo={(newPatientInfo: PatientInfo) => {
                        setValue("email", newPatientInfo.email, {
                          shouldValidate: true,
                        });
                      }}
                      disabled={isDisabled()}
                      province={
                        authContext?.profile?.locations[locationIdx].province
                      }
                      rules={{
                        required: { value: true, message: "PHN is required" },
                      }}
                      autocomplete
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <EmailInput
                      name="email"
                      label="Patient Email"
                      control={control}
                      rules={{
                        required: { value: true, message: "Email is required" },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* Delay email checkbox */}
              <Grid size={{ xs: 12 }}>
                <Tooltip
                  title="Delay sending the email until the next day at 7:00 AM MST"
                  placement="right"
                >
                  {/* Wrap in fragment to prevent error about refs from being thrown */}
                  <FormCheckbox
                    name="delaySend"
                    label="Delay sending"
                    control={control}
                  />
                </Tooltip>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <InputLabel>Subject</InputLabel>
                <FormTextField
                  name="subject"
                  control={control}
                  {...{
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          {getSubjectPrepend(
                            sendingOnBehalfOfOption,
                            displayName,
                          )}
                        </InputAdornment>
                      ),
                    },
                  }}
                  disabled={isDisabled()}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <InputLabel>Message</InputLabel>
                <FormTextArea
                  name="body"
                  control={control}
                  initRows={3}
                  disabled={isDisabled()}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <FormTextArea
                  name="signOff"
                  control={control}
                  initRows={3}
                  disabled={isDisabled()}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <InputLabel>Attachments</InputLabel>
              </Grid>
              <Grid size={{ xs: 12 }}>
                {createDropzone(
                  isMobileDevice()
                    ? "Tap to Upload"
                    : "Click Here or Drag and Drop to Upload",
                  "150px",
                )}
              </Grid>
              <Grid size={{ xs: 12 }}>{createFileList()}</Grid>
              <Grid size={{ xs: 12 }}>{createFileThumbs()}</Grid>
              <Grid size={{ xs: 12 }}>
                <ProcessStatus
                  state={processState}
                  errorMessage={processErrorMessage}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Button
                  type="submit"
                  color="primary"
                  disabled={optionMustBeChosen || isDisabled()}
                  startIcon={<Send color="primary" />}
                >
                  Send
                </Button>
                {isPremiumOneWayMessageSender && (
                  <InputLabel style={{ fontSize: "0.875rem" }}>
                    Uses Remaining this Month: {premiumUsesRemaining}
                  </InputLabel>
                )}
              </Grid>
            </Grid>
          </fieldset>
        </form>
      </PaperPage>
      <SuccessModal
        text={"Message sent successfully."}
        show={processState === ProcessState.success}
      />
      <LeavePagePrompt isDirty={isDirty} />
    </>
  );
};

export default OneWayMessaging;
