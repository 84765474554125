import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import routesDisplay from "src/routes/routesDisplay";
import "./index.css";

const domNode = document.getElementById("root");
if (!domNode) {
  throw new Error("No #root element found in the DOM");
}
const root = createRoot(domNode);
const App = () => (
  <RouterProvider router={createBrowserRouter([routesDisplay])} />
);
root.render(<App />);
