import { useState } from "react";
import { ProcessState } from "./ProcessStatus";

export interface ErrorHandlerOptions {
  /** Error object. If provided, the error will be logged to the console. */
  error?: Error;
  /** User message to display in the error status. If provided, the user message will be displayed to the user.*/
  userMessage?: string;
  /** Don't display error object message to user */
  hideErrorMessage?: boolean;
  /** Analytics logs name to log error in analytics */
  analyticsLog?: string;
  /** Optional analytics object to provide when logging analytics */
  analyticsLogObj?: any;
  silent?: boolean;
}

export interface UseProcessStateProps {
  /** If no error or userMessage provided, then default to this message. Default value is 'An error occurred' */
  defaultErrorMessage?: string;
  /** Analytics event logger to log analytics events */
  logAnalyticsEvent?: (analyticsLog: string, analyticsLogObj?: any) => void;
}

function useProcessState({
  defaultErrorMessage = "An error occurred.",
  logAnalyticsEvent = () => {},
}: UseProcessStateProps) {
  const [processState, setProcessState] = useState<ProcessState>(
    ProcessState.idle,
  );
  const [processErrorMessage, setProcessErrorMessage] = useState<string>("");
  const [processWarningMessage, setProcessWarningMessage] =
    useState<string>("");

  const errorHandler = (options: ErrorHandlerOptions) => {
    if (options.analyticsLog !== undefined) {
      logAnalyticsEvent(options.analyticsLog, options.analyticsLogObj);
    }

    if (options.error !== undefined) {
      console.error(options.error);
    }

    if (!options.silent) {
      //If silent, don't show anything to user
      let errorMessage = "";

      if (options.userMessage !== undefined) {
        errorMessage += options.userMessage;
      }
      if (
        !options.hideErrorMessage &&
        options.error !== undefined &&
        options.error !== null
      ) {
        if (errorMessage !== "") errorMessage += ": ";
        errorMessage += options.error.message;
      }
      if (errorMessage === "") {
        errorMessage = defaultErrorMessage;
      }
      setProcessErrorMessage(errorMessage);
      setProcessState(ProcessState.error);
    } else {
      setProcessState(ProcessState.idle);
    }
  };

  return {
    processState,
    setProcessState,
    processErrorMessage,
    setProcessErrorMessage,
    processWarningMessage,
    setProcessWarningMessage,
    errorHandler,
  };
}

export default useProcessState;
