import {
  ProcessState,
  ProcessStatus,
} from "@alethea-medical/alethea-components";
import Grid from "@mui/material/Grid2";
import MenuItem from "@mui/material/MenuItem";
import { Theme } from "@mui/material/styles";
import { format } from "date-fns";
import { FC, ReactElement } from "react";
import isMobileDevice from "src/models/isMobileDevice";
import { makeStyles } from "tss-react/mui";
import FormSelectV2 from "../../../../../components/FormInputFieldsV2/FormSelectV2";
import palette from "../../../../../palette";
import ResponseTime from "../../SpecialtyDropdown/ResponseTime";
import useConsultFormStyles from "../../useConsultFormStyles";
import AcceptanceFlags from "../AcceptanceFlags/AcceptanceFlags";
import {
  ServiceItem,
  getFormattedClinicCityProvinceFromService,
  getNextAvailableDate,
  getTitleAndCredsFromServiceUser,
  isServiceUnavailable,
} from "../serviceHelpers";
import ServiceDirectoryController, {
  ServiceDirectoryControllerProps,
} from "./ServiceDirectoryController";

const useStyles = makeStyles()((theme: Theme) => ({
  dropdownItem: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  dropdownMobileView: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    whiteSpace: "normal",
  },
  emptyMenuItem: {
    color: theme.palette.text.disabled,
  },
  mobileVacationText: {
    color: palette.orange,
    fontWeight: "bold",
  },
  webVacationText: {
    textAlign: "right",
    color: palette.orange,
    fontWeight: "bold",
  },
  additionalInfo: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
    },
  },
  collegeHeader: {
    fontWeight: "bold",
    backgroundColor: palette.lightGray,
  },
  acceptanceFlags: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      justifyContent: "start",
      marginRight: 0,
      marginBottom: -theme.spacing(0.1),
      gap: theme.spacing(2),
    },
    dropdownItem: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    dropdownMobileView: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    emptyMenuItem: {
      color: theme.palette.text.disabled,
    },
    primaryInfo: {
      whiteSpace: "normal",
    },
  },
}));

function noSpecialistAvailable(
  selectedSubsite: string,
  searchResults: ServiceItem[],
  searchProcessState: ProcessState,
): boolean {
  return (
    selectedSubsite !== "" &&
    searchResults.length === 0 &&
    searchProcessState === ProcessState.idle
  );
}

function getSpecialistEmptyMenu(
  selectedSubsite: string,
  searchResults: ServiceItem[],
  searchProcessState: ProcessState,
) {
  if (selectedSubsite === "") {
    return <MenuItem value={""}>Select Subsite First</MenuItem>;
  } else if (
    searchResults.length === 0 &&
    searchProcessState === ProcessState.idle
  ) {
    return <MenuItem value={""}>No Specialists Available</MenuItem>;
  } else if (searchProcessState === ProcessState.running) {
    return <MenuItem value={""}>Loading Specialists...</MenuItem>;
  } else if (searchProcessState === ProcessState.error) {
    return <MenuItem value={""}>Error Loading Specialists</MenuItem>;
  } else if (searchResults.length > 0) {
    return <MenuItem value={""}>Select Specialist</MenuItem>;
  }
  return null;
}

function getSubsiteEmptyMenu(
  selectedSpecialty: string,
  specialties: { [specialty: string]: { subsites: string[] } },
) {
  if (selectedSpecialty === "") {
    return <MenuItem value={""}>Select Specialty First</MenuItem>;
  } else if (specialties[selectedSpecialty]?.subsites?.length === 0) {
    return <MenuItem value={""}>No Subsites Available</MenuItem>;
  } else if (specialties[selectedSpecialty]?.subsites?.length > 0) {
    return <MenuItem value={""}>Select Subsite</MenuItem>;
  }
  return null;
}

// Renders a dropdown item for one specialist
const renderSpecialistDropdownItem = (
  service: ServiceItem,
  classes: any,
): ReactElement => {
  const serviceUser = service.service.users.find(
    (user) => user.uid === service.service.serviceOwnerUid,
  );
  const isUnavailable = isServiceUnavailable(service.service);
  const nextAvailableDate = getNextAvailableDate(service.service);
  const titleAndCreds = getTitleAndCredsFromServiceUser(serviceUser);

  const additionalInfo = () => {
    return (
      <Grid className={classes.additionalInfo}>
        <div className={classes.acceptanceFlags}>
          <AcceptanceFlags
            acceptanceFlags={service.service.acceptanceFlags}
            size="2.5em"
            mobileSize="1em"
          />
        </div>
        {ResponseTime({
          responseTimeMillis: service.service.econsultResponseTime,
          fontSize: "small",
        })}
      </Grid>
    );
  };

  const specialistItem = (
    <Grid container alignItems="center" className={classes.dropdownItem}>
      <Grid className={classes.dropdownMobileView}>
        <div className={classes.primaryInfo}>
          {serviceUser?.userName} -{" "}
          {getFormattedClinicCityProvinceFromService(service.service)}
        </div>
        <div style={{ whiteSpace: "normal" }}>
          {titleAndCreds}
          {isUnavailable &&
            (isMobileDevice() ? (
              <Grid className={classes.mobileVacationText}>
                Unavailable{" "}
                {nextAvailableDate &&
                  `- Returning ${format(nextAvailableDate, "MMM d, yyyy")}`}
              </Grid>
            ) : (
              <span className={classes.webVacationText}>
                {" "}
                Unavailable{" "}
                {nextAvailableDate &&
                  `- Returning ${format(nextAvailableDate, "MMM d, yyyy")}`}
              </span>
            ))}
        </div>
        {isMobileDevice() && additionalInfo()}
      </Grid>
      {!isMobileDevice() && additionalInfo()}
    </Grid>
  );

  return (
    <MenuItem key={`service-item-${service.id}`} value={service.id}>
      {specialistItem}
    </MenuItem>
  );
};

function renderSpecialistDropdownItemsByCollege(
  classes: { collegeHeader: string },
  searchResults: ServiceItem[],
): ReactElement[] {
  // Step 1: Group the specialists by their college
  const specialistsByCollege = new Map<string, ServiceItem[]>();

  searchResults.forEach((service) => {
    const college = service.service.college;
    if (!specialistsByCollege.has(college)) {
      specialistsByCollege.set(college, []);
    }
    specialistsByCollege.get(college)?.push(service);
  });

  const items: ReactElement[] = [];

  // Define the desired order of the colleges
  const collegeOrder = ["FRCPC", "CCFP"];

  // Step 2: Create an array to pop the specialists into
  // Sort the colleges based on the defined order
  const sortedColleges = Array.from(specialistsByCollege.keys()).sort(
    (a, b) => {
      const indexA = collegeOrder.indexOf(a);
      const indexB = collegeOrder.indexOf(b);
      // If the key is not found in collegeOrder, sort alphabetically
      if (indexA === -1 && indexB === -1) {
        return a.localeCompare(b);
      }
      // One college not found in collegeOrder
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    },
  );

  // Step 3: Render the specialists, grouped by college
  sortedColleges.forEach((college) => {
    const services = specialistsByCollege.get(college) || [];
    // Determine the header text based on the college name
    let collegeHeaderText: string;
    if (college === "FRCPC") {
      collegeHeaderText = "FRCPC/FRCSC Specialists";
    } else if (college === "CCFP") {
      collegeHeaderText = "CCFP Consultants";
    } else {
      collegeHeaderText = college;
    }

    // Add the college header
    items.push(
      <MenuItem
        key={`college-header-${college}`}
        disabled
        className={classes.collegeHeader}
      >
        {collegeHeaderText}
      </MenuItem>,
    );

    // Add the specialists
    services.forEach((service) => {
      items.push(renderSpecialistDropdownItem(service, classes));
    });
  });

  return items;
}

const ServiceDirectory: FC<ServiceDirectoryControllerProps> = (props) => {
  const { classes: consultFormclasses } = useConsultFormStyles();
  const { classes } = useStyles();

  const {
    control,
    searchProcessState,
    searchResults,
    selectedService,
    specialties,
    selectedSpecialty,
    selectedSubsite,
    handleSelectSpecialty,
    handleSelectSubsite,
    handleSelectSpecialist,
    isDisabled,
    processState,
    processErrorMessage,
  } = ServiceDirectoryController(props);

  return (
    <div className={consultFormclasses.spacing}>
      <div className={consultFormclasses.spacing}>
        <ProcessStatus
          state={processState}
          errorMessage={processErrorMessage}
          loadingMessage="Loading Specialties..."
        />
      </div>
      <div className={consultFormclasses.spacing}>
        <FormSelectV2
          controlProps={{
            control,
            name: "specialty",
          }}
          inputFieldProps={{
            label: "Specialty",
            disabled: isDisabled,
            SelectProps: {
              displayEmpty: true,
              onChange: handleSelectSpecialty,
            },
            //Force the label to always shrink to the top so that it doesn't overlap the empty value
            InputLabelProps: {
              shrink: true,
            },
          }}
        >
          <MenuItem value={""} className={classes.emptyMenuItem}>
            Select Specialty
          </MenuItem>
          {Object.keys(specialties).map((specialty: string) => {
            return (
              <MenuItem key={`specialty-item-${specialty}`} value={specialty}>
                {specialty}
              </MenuItem>
            );
          })}
        </FormSelectV2>
      </div>
      <div className={consultFormclasses.spacing}>
        <FormSelectV2
          controlProps={{
            control,
            name: "subsite",
          }}
          inputFieldProps={{
            label: "Subsite",
            disabled: isDisabled || selectedSpecialty === "",
            SelectProps: {
              displayEmpty: true,
              onChange: handleSelectSubsite,
            },
            //Force the label to always shrink to the top so that it doesn't overlap the empty value
            InputLabelProps: {
              shrink: true,
            },
          }}
        >
          {getSubsiteEmptyMenu(selectedSpecialty, specialties)}
          {(specialties[selectedSpecialty]
            ? specialties[selectedSpecialty].subsites
            : []
          ).map((subsite: string) => {
            return (
              <MenuItem key={`subsite-item-${subsite}`} value={subsite}>
                {subsite}
              </MenuItem>
            );
          })}
        </FormSelectV2>
      </div>
      <div className={consultFormclasses.spacing}>
        <FormSelectV2
          controlProps={{
            control,
            name: "serviceId",
            rules: {
              required: { value: true, message: "A specialist is required" },
              validate: {
                isOnVacation: () => {
                  if (!selectedService) return undefined;
                  return isServiceUnavailable(selectedService.service)
                    ? "Specialist is unavailable, please save draft and wait for return or select another specialist."
                    : undefined;
                }, // return message if true, undefined if false
              },
            },
          }}
          inputFieldProps={{
            label: "Specialist",
            disabled:
              isDisabled ||
              selectedSubsite === "" ||
              noSpecialistAvailable(
                selectedSubsite,
                searchResults,
                searchProcessState,
              ),
            slotProps: {
              select: {
                displayEmpty: true,
                onChange: handleSelectSpecialist,
              },
              inputLabel: {
                //Force the label to always shrink to the top so that it doesn't overlap the empty value
                shrink: true,
              },
            },
          }}
        >
          {getSpecialistEmptyMenu(
            selectedSubsite,
            searchResults,
            searchProcessState,
          )}
          {renderSpecialistDropdownItemsByCollege(classes, searchResults)}
        </FormSelectV2>
      </div>
    </div>
  );
};

export default ServiceDirectory;
