import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import Grid from "@mui/material/Grid2";
import { FC } from "react";
import usePermissions from "../../components/usePermissions";
import { TypedFormTextField } from "../FormInputFields/FormTextField";

interface SelectSendOnBehalfOfProps {
  control: any;
  defaultValue?: string;
}

const DisplayName: FC<SelectSendOnBehalfOfProps> = ({ control }) => {
  const { granted: sendMsgOnBehalfOfPhysicianOrClinic } = usePermissions({
    resourceKey: resourceKeys.sendMsgOnBehalfOfPhysicianOrClinic,
  });
  return (
    sendMsgOnBehalfOfPhysicianOrClinic && (
      <Grid size={{ xs: 12, md: 6 }}>
        <TypedFormTextField
          control={control}
          name="displayName"
          label="Edit name to display in Form"
        />
      </Grid>
    )
  );
};

export default DisplayName;
