import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { dbNames } from "shared/db-keys/dist";
import {
  type CustomCalendar,
  type WeekDay,
  WeekDays,
  defaultDaysSelected,
  getWeekDayDisplayName,
} from "shared/types/dist/namespaces/SpecialistAvailability";
import { AuthContext } from "src/AuthProvider";
import HelpModal from "src/components/HelpModal";
import { ProcessStatus } from "src/components/ProcessState";
import SaveDiscardToolbar from "src/components/SaveDiscardToolbar";
import { fbFirestore, logAnalyticsEvent } from "src/firebase";

const hasSameSelectedDays = (days1: CustomCalendar, days2: CustomCalendar) => {
  return Object.values(WeekDays).every((day) => days1[day] === days2[day]);
};

// Checks if no days are selected. This means user is unavailable for all days, and we don't want this because it makes them unavailable indefinitely
const noDaysSelected = (days: CustomCalendar) => {
  return Object.values(WeekDays).every((day) => !days[day]);
};

const isDaysSelected = (data: any): data is CustomCalendar => {
  return (
    data &&
    typeof data === "object" &&
    Object.values(WeekDays).every((day) => typeof data[day] === "boolean") &&
    Object.keys(data).every((key) =>
      Object.values(WeekDays).includes(key as any),
    )
  );
};

type DayCheckboxesProps = {
  daysSelected: CustomCalendar;
  onDaySelected: (day: WeekDay, checked: boolean) => void;
};

const DayCheckboxes: FC<DayCheckboxesProps> = ({
  daysSelected,
  onDaySelected,
}) => {
  return (
    <Box sx={{ px: 1, display: "flex", flexDirection: "column" }}>
      {Object.values(WeekDays).map((day) => (
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={daysSelected[day]}
                onChange={(e) => onDaySelected(day, e.target.checked)}
                name={day}
              />
            }
            label={getWeekDayDisplayName(day)}
          />
        </Box>
      ))}
    </Box>
  );
};

const CustomCalendar = () => {
  const [days, setDays] = useState<CustomCalendar>(defaultDaysSelected);
  const [savedDays, setSavedDays] =
    useState<CustomCalendar>(defaultDaysSelected);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const authContext = useContext(AuthContext);
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  // fetch data from firebase on load
  useEffect(() => {
    setProcessState(ProcessState.running);
    fbFirestore
      .collection(dbNames.specialistAvailabilitySettings)
      .doc(authContext.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const customCalendar = doc.data()?.customCalendar;
          if (customCalendar && isDaysSelected(customCalendar)) {
            setDays(customCalendar);
            setSavedDays(customCalendar);
          }
        }
      })
      .catch(errorHandler)
      .then(() => {
        setProcessState(ProcessState.idle);
      });
  }, [
    authContext.uid,
    dbNames.specialistAvailabilitySettings,
    setDays,
    setSavedDays,
    setProcessState,
  ]);

  const handleCheckboxChange = useCallback(
    (day: WeekDay, checked: boolean) => {
      setDays((oldDays) => {
        const newDays = { ...oldDays, [day]: checked };
        if (hasSameSelectedDays(newDays, savedDays) || noDaysSelected(newDays))
          setIsDirty(false);
        else setIsDirty(true);
        return newDays;
      });
    },
    [setDays, setIsDirty, savedDays],
  );

  const saveSpecialistCustomCalendar = useCallback(async () => {
    setProcessState(ProcessState.running);
    await fbFirestore
      .collection(dbNames.specialistAvailabilitySettings)
      .doc(authContext.uid)
      .set(
        {
          customCalendar: days,
        },
        { merge: true },
      );
    setSavedDays(days);
    setIsDirty(false);
    setProcessState(ProcessState.success);
  }, [authContext.uid, dbNames.specialistAvailabilitySettings, days]);

  const discardSpecialistCustomCalendar = useCallback(() => {
    setDays(savedDays);
    setIsDirty(false);
  }, [setDays, setIsDirty, savedDays]);

  return (
    <Box sx={{ padding: 1 }}>
      <SaveDiscardToolbar
        sx={{ my: 1 }}
        show={isDirty}
        modalText={"Discard changes to your custom calendar settings?"}
        saveHandler={saveSpecialistCustomCalendar}
        discardHandler={discardSpecialistCustomCalendar}
        unsavedText="Unsaved Settings"
      />
      <ProcessStatus
        sx={{ my: 1 }}
        state={processState}
        errorMessage={processErrorMessage}
      />
      <Typography>
        Set your availability for new eConsults on specific days.
        <Box
          component={"span"}
          sx={{
            display: "inline-flex",
            verticalAlign: "middle",
            marginLeft: 1,
          }}
        >
          <HelpModal
            helpText={[
              `Select the days you'd like to receive new eConsults. On unchecked days, your account will be marked as unavailable. You may still receive follow-up messages on existing consults.`,
            ]}
          />
        </Box>
      </Typography>
      <DayCheckboxes daysSelected={days} onDaySelected={handleCheckboxChange} />
    </Box>
  );
};

export default CustomCalendar;
