import { PaperPage } from "@alethea-medical/alethea-components";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import { InputLabel } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, {
  useEffect,
  useContext,
  FC,
  useCallback,
  useState,
  useMemo,
} from "react";
import {
  useFieldArray,
  useForm,
  useFormState,
  useWatch,
} from "react-hook-form";
import analyticsLogs from "src/analyticsLogs";
import DisplayName from "src/components/DisplayNameInForm";
import {
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "src/components/ProcessState";
import { AuthContext } from "../../../AuthProvider";
import HelpModal from "../../../components/HelpModal";
import LeavePagePrompt from "../../../components/LeavePagePrompt";
import LocationDropdown from "../../../components/LocationDropdown";
import SelectSendOnBehalfOf from "../../../components/SelectSendOnBehalfOf";
import SuccessModal from "../../../components/SuccessModal";
import usePermissions from "../../../components/usePermissions";
import { fbFunctions, logAnalyticsEvent } from "../../../firebase";
import serverRequest from "../../../models/serverRequest";
import AddPatientButton from "./AddPatientButton";
import DelayAllCheckbox from "./DelayAllCheckbox";
import FormSendButton from "./FormSendButton";
import { FormsSelect } from "./FormsSelect";
import PatientRow from "./PatientRow";

// TODO: show warning if more patients in send list than remaining premium uses

const canSendPremiumForms = async (usages?: number): Promise<boolean> => {
  try {
    const { data } = await fbFunctions.httpsCallable("premium-canSendForms")({
      usages,
    });
    return data;
  } catch (e) {
    console.error("Error checking if user can send premium forms:", e);
    return false;
  }
};

const sendPremiumForm = async (usages?: number): Promise<boolean> => {
  try {
    const { data } = await fbFunctions.httpsCallable("premium-sendForms")({
      usages,
    });
    return data;
  } catch (e) {
    console.error("Error sending premium form", e);
    return false;
  }
};

const getNumberOfPremiumFormUsesRemaining = async (): Promise<
  number | string
> => {
  try {
    const { data } = await fbFunctions.httpsCallable(
      "premium-getNumberOfFormUsesRemaining",
    )();
    return data;
  } catch (e) {
    console.error("Error getting number of premium form uses remaining", e);
    return 0;
  }
};

export interface FormPatient {
  email: string;
  phn: string;
  delay: boolean;
}

const FormSender: FC = () => {
  const { handleSubmit, control, setValue, reset, watch, getValues } = useForm({
    mode: "onTouched",
  });
  const { isDirty } = useFormState({ control });
  const { granted: sendMsgOnBehalfOfPhysicianOrClinic } = usePermissions({
    resourceKey: resourceKeys.sendMsgOnBehalfOfPhysicianOrClinic,
  });
  const sendingOnBehalfOfOption = useWatch({
    control,
    name: "sendingOnBehalfOfOption",
  });
  const [optionMustBeChosen, setOptionMustBeChosen] = React.useState(true);

  const { granted: isPremiumFormSender } = usePermissions({
    resourceKey: resourceKeys.premiumForms,
  });

  const {
    processState,
    setProcessState,
    processErrorMessage,
    processWarningMessage,
    setProcessWarningMessage,
    errorHandler,
  } = useProcessState({ logAnalyticsEvent });

  const [premiumUsesRemaining, setPremiumUsesRemaining] = React.useState<
    number | string
  >("loading...");
  const [canPremiumUserSendForms, setCanPremiumUserSendForms] = useState(false);
  const onSendPremiumForm = useCallback(
    async (numRecipients: number) => {
      await sendPremiumForm(numRecipients);
      // Use this instead of getting from server to reduce firebase costs
      setPremiumUsesRemaining((prev) => {
        if (typeof prev === "number") return prev - numRecipients;
        return prev;
      });
      // Need to check if user can send more messages
      const maySend = await canSendPremiumForms();
      setCanPremiumUserSendForms(maySend);
    },
    [
      setPremiumUsesRemaining,
      sendPremiumForm,
      canSendPremiumForms,
      setCanPremiumUserSendForms,
    ],
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: "patients",
  });

  // Check if a premium one way messaging user has remaining messages
  useEffect(() => {
    const updateCanPremiumUserSendForms = async () => {
      if (!isPremiumFormSender) {
        return;
      }
      setProcessState(ProcessState.running);

      const [maySendMessages, numberOfRemainingMessages] = await Promise.all([
        await canSendPremiumForms(),
        await getNumberOfPremiumFormUsesRemaining(),
      ]);
      setCanPremiumUserSendForms(maySendMessages);
      setPremiumUsesRemaining(numberOfRemainingMessages);

      setProcessState(ProcessState.idle);
    };
    updateCanPremiumUserSendForms();
  }, [
    setCanPremiumUserSendForms,
    isPremiumFormSender,
    setProcessState,
    setPremiumUsesRemaining,
    ProcessState,
  ]);

  const authContext = useContext(AuthContext);
  const locationIdx = watch("locationIdx");

  const isPremiumAndUnableToSend = useMemo(
    () => isPremiumFormSender && !canPremiumUserSendForms,
    [isPremiumFormSender, canPremiumUserSendForms],
  );

  useEffect(() => {
    reset({
      locationIdx: getValues("locationIdx"),
      patients: [
        {
          phn: "",
          email: "",
        },
      ],
    });
  }, []);

  useEffect(() => {
    setOptionMustBeChosen(
      sendMsgOnBehalfOfPhysicianOrClinic && !sendingOnBehalfOfOption,
    ); // If MOA can, MOA MUST choose someone
  }, [authContext.user, sendMsgOnBehalfOfPhysicianOrClinic]);

  useEffect(() => {
    setOptionMustBeChosen(
      sendMsgOnBehalfOfPhysicianOrClinic && !sendingOnBehalfOfOption,
    ); // If MOA can, MOA MUST choose someone
  }, [locationIdx, sendingOnBehalfOfOption]);

  const onSubmit = async (data: any) => {
    setProcessState(ProcessState.running);

    logAnalyticsEvent(analyticsLogs.forms.start, {
      numPatients: data.patients.length,
      form: data.selectedForm.name,
    });

    if (isPremiumFormSender) {
      const numRecipients = data.patients.length;
      const canSendForms = await canSendPremiumForms(numRecipients);
      if (!canSendForms) {
        errorHandler({
          userMessage:
            typeof premiumUsesRemaining === "number" && premiumUsesRemaining > 0
              ? `You may not send more forms than you have remaining because you have ${premiumUsesRemaining} use${
                  premiumUsesRemaining === 1 ? "" : "s"
                } remaining this month. Please remove ${
                  numRecipients - premiumUsesRemaining
                } recipient${
                  numRecipients - premiumUsesRemaining === 1 ? "" : "s"
                }.`
              : "You have reached your limit of forms.",
          analyticsLog: analyticsLogs.premium.premiumUsage.form.maxReached,
        });
        return;
      }
    }

    //Build body for https request
    const body: any = {
      physicianUid: authContext.uid,
      locationIdx: data.locationIdx,
      formId: data.selectedForm.uid,
      patients: data.patients.map((patient: FormPatient) => {
        return {
          phn: patient.phn,
          email: patient.email,
          delay: patient.delay,
        };
      }),
    };

    if (data.sendingOnBehalfOfOption) {
      body.sendingOnBehalfOfOptionKind = data.sendingOnBehalfOfOption.isClinic
        ? "clinic"
        : "physician";
      body.sendingOnBehalfOfOptionUid = data.sendingOnBehalfOfOption.id;
      body.sendingOnBehalfOfOptionDisplayName = data.displayName;
    }

    //Send request to server to send forms
    serverRequest(
      authContext.user,
      { "Content-Type": "application/json" },
      JSON.stringify(body),
      "send-forms",
    )
      .then(async () => {
        if (isPremiumFormSender)
          logAnalyticsEvent(analyticsLogs.premium.premiumUsage.form.success, {
            numSent: data.patients.length,
          });
        else logAnalyticsEvent(analyticsLogs.forms.success);

        // Tell backend the message is sent by a premium user
        if (isPremiumFormSender) {
          await onSendPremiumForm(data.patients.length);
        }

        reset({
          locationIdx: data.locationIdx,
          selectedForm: data.selectedForm,
          patients: [],
          sendingOnBehalfOfOption: getValues("sendingOnBehalfOfOption"),
        });
        append({
          phn: "",
          email: "",
        });

        setProcessState(ProcessState.success);
        setTimeout(() => {
          setProcessState(ProcessState.idle);
        }, 1000);
      }) //Catch any errors on the server side. Will also catch error if all emails fail to send
      .catch((error: Error) => {
        errorHandler({
          error,
          userMessage: "Error sending forms",
          analyticsLog: isPremiumFormSender
            ? analyticsLogs.premium.premiumUsage.form.fail
            : "form_sender_failed",
        });
      });
  };

  const onError = () => {
    errorHandler({
      userMessage: "Check form for errors.",
    });
  };

  const isDisabled = () => {
    return (
      processState === ProcessState.running ||
      processState === ProcessState.success ||
      isPremiumAndUnableToSend
    );
  };

  // Sync display name with sending on behalf of name
  useEffect(() => {
    const prefix =
      sendingOnBehalfOfOption?.isClinic || !sendingOnBehalfOfOption
        ? ""
        : "Dr. ";
    const displayName = sendingOnBehalfOfOption?.label ?? "";
    setValue("displayName", `${prefix}${displayName}`, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [sendingOnBehalfOfOption, getValues, setValue]);

  useEffect(() => {
    if (
      premiumUsesRemaining !== undefined &&
      typeof premiumUsesRemaining === "number" &&
      premiumUsesRemaining < fields.length
    ) {
      setProcessWarningMessage(
        "You are trying to send more forms than you have left. Additional forms may not send.",
      );
      setProcessState(ProcessState.warning);
    } else if (processState === ProcessState.warning) {
      setProcessState(ProcessState.idle);
    }
  }, [premiumUsesRemaining, fields, setProcessWarningMessage]);

  return (
    <>
      <PaperPage enablePadding>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          autoComplete="off"
          aria-autocomplete="none"
        >
          <fieldset disabled={isDisabled()} aria-autocomplete="none">
            <Grid container spacing={1} alignItems="center">
              <Grid size={{ xs: 12 }}>
                <HelpModal
                  buttonText="About Forms"
                  helpText={[
                    "The selected form will be individually emailed to each patient.",
                    "If delay is checked, the form is sent the next day at 7:00 AM MST.",
                  ]}
                />
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <LocationDropdown
                  name="locationIdx"
                  control={control}
                  label="Clinic"
                  disabled={isDisabled()}
                  setValue={setValue}
                />
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <SelectSendOnBehalfOf control={control} />
              </Grid>

              <DisplayName control={control} />

              <Grid size={{ xs: 12 }}>
                <FormsSelect
                  control={control}
                  setValue={setValue}
                  disabled={isDisabled()}
                />
              </Grid>

              <Grid size={{ xs: 12 }}>
                <AddPatientButton append={append} disabled={isDisabled()} />
              </Grid>

              {/* Patient rows */}
              {fields.map((field, index) => (
                <PatientRow
                  key={field.id}
                  control={control}
                  index={index}
                  setValue={setValue}
                  remove={remove}
                  disabled={isDisabled()}
                  province={
                    authContext?.profile?.locations[locationIdx]?.province
                  }
                />
              ))}

              {/* Add button */}
              <Grid size={{ xs: 12 }}>
                <AddPatientButton append={append} disabled={isDisabled()} />
              </Grid>
              <Grid size={{ xs: 12 }}>
                {/* Delay email checkbox */}
                <DelayAllCheckbox control={control} setValue={setValue} />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <ProcessStatus
                  state={processState}
                  errorMessage={processErrorMessage}
                  warningMessage={processWarningMessage}
                  successMessage="Forms Sent Successfully"
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <FormSendButton
                  control={control}
                  disabled={optionMustBeChosen || isDisabled()}
                />
                {isPremiumFormSender && (
                  <InputLabel style={{ fontSize: "0.875rem" }}>
                    Uses Remaining this Month: {premiumUsesRemaining}
                  </InputLabel>
                )}
              </Grid>
            </Grid>
          </fieldset>
        </form>
      </PaperPage>
      <SuccessModal
        text={"Forms sent successfully."}
        show={processState === ProcessState.success}
      />
      <LeavePagePrompt isDirty={isDirty} />
    </>
  );
};

export default FormSender;
