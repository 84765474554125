import { AIType } from "@alethea-medical/aletheamd-types";

const analyticsLogs = {
  ai: {
    aiPredict: (aiType: AIType) => `ai_predict_${aiType}`,
    aiPredictCantDiagnose: (aiType: AIType) =>
      `ai_predict_${aiType}_cant_diagnose`,
    aiPredictFail: "ai_predict_fail",
  },
  ava: {
    consult: {
      open: "ava_consult_open",
      openFail: "ava_consult_open_fail",
    },
    gallery: {
      upload: "ava_gallery_upload",
      uploadFail: "ava_gallery_upload_fail",
    },
  },
  aveiro: {
    paymentSubmit: {
      success: "aveiro_payment_submit",
      fail: "aveiro_payment_submit_fail",
    },
  },
  consultDraft: {
    open: "consult_draft_open",
    openFail: "consult_draft_open_fail",
    save: "consult_draft_save",
    saveFail: "consult_draft_save_fail",
    discard: "consult_draft_discard",
    discardFail: "consult_draft_discard_fail",
    update: "consult_draft_update",
    updateFail: "consult_draft_update_fail",
    export: "consult_draft_export_pdf",
    exportFail: "consult_draft_export_pdf_fail",
    forward: "consult_draft_forward",
    forwardFail: "consult_draft_forward_fail",
  },
  econsult: {
    send: "portal_referral_success",
    sendFail: "portal_referral_failed",
    changeActivityType: {
      phoneConsult: "consult_change_activity_phone_consult",
      econsult: "consult_change_activity_econsult",
    },
  },
  econsultOutcomes: {
    success: "econsult_outcome_success",
    fail: "econsult_outcome_failed",
  },
  forms: {
    success: "form_success",
    start: "form_start",
  },
  gallery: {
    addTags: "gallery_add_tags",
    addNotes: "gallery_add_notes",
    delete: "gallery_delete_file",
    deleteFail: "gallery_delete_file_fail",
    downloadFile: "gallery_download_file",
    downloadFileFail: "gallery_download_file_fail",
    fetchFail: "gallery_fetch_fail",
  },
  imageAnnotation: {
    econsult: {
      save: "econsult_image_annotation_save",
      saveFail: "econsult_image_annotation_save_fail",
    },
  },
  interpretation: {
    sinusEndoscopy: {
      bill: "interpretation_sinus_endoscopy_bill",
    },
    audiogram: {
      bill: "interpretation_audiogram_bill",
    },
    billFail: "interpretation_bill_fail",
  },
  inviteAColleauge: {
    invite: {
      success: "invite_a_colleague_invite",
      fail: "invite_a_colleague_invite_fail",
    },
  },
  moaConsultInbox: {
    search: "moa_consult_inbox_search",
  },
  oneWayMessaging: {
    success: "one_way_messaging_success",
    start: "one_way_messaging_start",
  },
  phoneConsult: {
    markCallComplete: {
      success: "phone_consult_call_complete",
      fail: "phone_consult_call_complete_fail",
    },
    schedule: {
      fetch: "phone_consult_schedule_fetch",
      fetchFail: "phone_consult_schedule_fetch_fail",
    },
    send: "phone_consult_send",
    sendFail: "phone_consult_send_fail",
  },
  premium: {
    pageVisit: "premium_page_visit",
    signUp: "premium_sign_up",
    premiumUsage: {
      oneWayMessage: {
        success: "premium_usage_one_way_message",
        maxReached: "premium_usage_one_way_message_max_reached",
        fail: "premium_usage_one_way_message_fail",
      },
      form: {
        success: "premium_usage_form",
        maxReached: "premium_usage_form_max_reached",
        fail: "premium_usage_form_fail",
      },
    },
  },
  pushNotifications: {
    open: "push_notification_open",
  },
  secureMessaging: {
    move: {
      archive: "secure_message_move_to_archive",
      inbox: "secure_message_move_to_inbox",
      undo: "secure_message_move_undo",
    },
    markUnread: "secure_message_mark_unread",
    search: "secure_message_search",
    selectActivity: "secure_messaging_select_activity",
    toggleBillOn: "secure_messaging_toggle_billing_on",
    toggleBillOff: "secure_messaging_toggle_billing_off",
    notifications: {
      mute: "secure_message_mute_notifications",
      unmute: "secure_message_unmute_notifications",
    },
    exportPdf: {
      start: "secure_messaging_export_pdf_start",
      success: "secure_messaging_export_pdf_success",
      fail: "secure_messaging_export_pdf_failed",
    },
    status: {
      changeStatus: "secure_messaging_change_status",
      changeStatusFail: "secure_messaging_change_status_fail",
    },
    message: {
      send: "secure_message_send_success",
      sendFail: "secure_message_send_failed",
    },
  },
  services: {
    selectSearchBarService: "services_select_search_bar_service",
    selectDirectoryService: "services_select_directory_service",
    clickSearchBar: "services_click_search",
    clickDirectory: "services_click_directory",
    performSearchBarSearch: "services_perform_search_bar_search",
    performDirectorySearch: "services_perform_directory_search",
    consultSentFromSearchBar: "services_consult_sent_from_search_bar",
    consultSentFromDirectory: "services_consult_sent_from_directory",
    error: {
      searchBarSearch: "services_error_search_bar_search",
      directorySearch: "services_error_directory_search",
    },
  },
  specialistAvailability: {
    phoneConsultCalendar: {
      save: "specialist_phone_consult_calendar_save",
      saveFail: "specialist_phone_consult_calendar_save_fail",
    },
    vacation: {
      save: "specialist_vacation_save",
      saveFail: "specialist_vacation_save_fail",
    },
  },
  specialistTemplates: {
    fetchFail: "specialist_templates_fetch_fail",
    save: "specialist_templates_save",
    saveFail: "specialist_templates_save_fail",
    insertOption: "specialist_template_insert_option",
  },
  specialistResources: {
    fetchFail: "specialist_resources_fetch_fail",
    save: "specialist_resources_save",
    saveFail: "specialist_resources_save_fail",
    insertOption: "specialist_resources_insert_option",
  },
};
export default analyticsLogs;
