import { alertClasses } from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { AuthProvider } from "src/AuthProvider";
import NewVersionReload from "src/ReloadPrompt";
import AppUrlListener from "src/components/AppDeepLinks";
import Analytics from "src/config/Analytics";
import ClaimsProvider from "src/config/ClaimsProvider";
import PushNotifications from "src/config/PushNotifications";
import palette from "src/palette";
import "./RoutingWrapper.css";

// react-pdf Imports
import { isAndroid } from "react-device-detect";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import AndroidAPKUpdateProvider from "src/config/AndroidAPKUpdateProvider";

// #region react-pdf Config
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();
// #endregion

// #region Theme Config
const breakpoints = createTheme().breakpoints;
const theme = createTheme({
  palette: {
    background: {
      default: "#fafafa",
    },
    primary: {
      main: palette.darkGreen,
    },
    secondary: {
      main: palette.lightGreen,
      light: palette.lightGreenLight,
    },
    text: {
      primary: grey[800],
    },
  },
  typography: {
    fontFamily: [
      "Proxima Nova",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    subtitle1: {
      color: "#888",
    },
  },
  // TODO when moving into a separate component library, move these styles directly into their components
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1.0em",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardError: {
          backgroundColor: palette.orangeLightLight, //background
          [`& .${alertClasses.icon}`]: {
            color: palette.orange, //icon
          },
        },
        standardInfo: {
          color: palette.darkGreen, //text
          backgroundColor: palette.lightGreenLight,
          [`& .${alertClasses.icon}`]: {
            color: palette.darkGreen,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          [breakpoints.up("sm")]: {
            minWidth: "11em",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
      },
    },
  },
});
// #endregion

const CustomOutlet: FC = () => {
  return (
    <>
      <Analytics />
      <PushNotifications />
      <AppUrlListener />
      <Outlet />
    </>
  );
};

const RoutingWrapper: FC = () => (
  <AuthProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <ClaimsProvider>
          <CssBaseline />
          <Container disableGutters maxWidth={false}>
            <div style={{ display: "flex" }}>
              {isAndroid ? (
                <AndroidAPKUpdateProvider>
                  <CustomOutlet />
                </AndroidAPKUpdateProvider>
              ) : (
                <CustomOutlet />
              )}
              <NewVersionReload />
            </div>
          </Container>
        </ClaimsProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </AuthProvider>
);

export default RoutingWrapper;
