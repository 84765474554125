import {
  HeightLayout,
  HeightLayoutChild,
  useScreenSize,
  useSizeManager,
} from "@alethea-medical/alethea-components";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import Alert from "@mui/material/Alert";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Theme } from "@mui/material/styles";
import { FC, useCallback, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import usePermissions from "../../../../components/usePermissions";
import isMobileDevice from "../../../../models/isMobileDevice";
import SpecialistScheduleView from "./Calendar/SpecialistScheduleView";
import ConsultLimit from "./ConsultLimit/ConsultLimits";
import CustomCalendar from "./CustomCalendar/CustomCalendar";
import SpecialistVacation from "./Vacation/Vacation";

const useStyles = makeStyles()((theme: Theme) => ({
  hidden: {
    display: "none",
  },
  notAvailableOnMobile: {
    margin: theme.spacing(1),
  },
}));

const ScheduleTabs = {
  unavailable: "unavailable",
  phoneConsultCalendar: "phoneConsultCalendar",
  consultLimit: "consultLimit",
  customCalendar: "customCalendar",
} as const;

type EditSchedulePageTab = (typeof ScheduleTabs)[keyof typeof ScheduleTabs];

const ScheduleTabNames: {
  [key in keyof typeof ScheduleTabs]: string;
} = {
  unavailable: "Unavailable",
  phoneConsultCalendar: "Phone Consult Schedule",
  consultLimit: "Consult Limits",
  customCalendar: "Custom Calendar",
} as const;

const EditSchedulePage: FC = () => {
  const { classes, cx } = useStyles();
  const [tab, setTab] = useState<EditSchedulePageTab>();
  const [loading, setLoading] = useState(true);

  const { heightMinusAppBar } = useScreenSize({});

  const { height, sizeRef, updateSize } = useSizeManager();
  const handleSetTab = useCallback(
    (newTab: EditSchedulePageTab) => {
      setTab(newTab);
      updateSize();
    },
    [setTab, updateSize],
  );

  const { granted: phoneConsultGranted } = usePermissions({
    resourceKey: resourceKeys.phoneConsult,
    onPermissionFetched: (granted: boolean) => {
      setLoading(false);
      if (granted) {
        handleSetTab(ScheduleTabs.phoneConsultCalendar);
      } else {
        handleSetTab(ScheduleTabs.unavailable);
      }
    },
  });

  useEffect(() => {
    updateSize();
  }, [updateSize]);

  if (loading) {
    return <p>Loading...</p>;
  }

  // TODO the height of something here isn't being calculated correctly, which breaks the "Add Vacation" section in mobile. Need to figure out how to fix this.
  return (
    <HeightLayout height={heightMinusAppBar}>
      <HeightLayoutChild flexDriver>
        <Tabs
          value={tab}
          onChange={(_, newTab) => {
            handleSetTab(newTab);
          }}
          orientation={"horizontal"}
        >
          <Tab
            className={cx({ [classes.hidden]: !phoneConsultGranted })}
            value={ScheduleTabs.phoneConsultCalendar}
            label={ScheduleTabNames.phoneConsultCalendar}
          />
          <Tab
            value={ScheduleTabs.unavailable}
            label={ScheduleTabNames.unavailable}
          />
          <Tab
            value={ScheduleTabs.customCalendar}
            label={ScheduleTabNames.customCalendar}
          />
          <Tab
            value={ScheduleTabs.consultLimit}
            label={ScheduleTabNames.consultLimit}
          />
        </Tabs>
      </HeightLayoutChild>
      <HeightLayoutChild flexDriven ref={sizeRef}>
        {phoneConsultGranted && (
          <div
            className={cx({
              [classes.hidden]: tab !== ScheduleTabs.phoneConsultCalendar,
            })}
          >
            {!isMobileDevice() && <SpecialistScheduleView height={height} />}
            {isMobileDevice() && (
              <Alert severity="info" className={classes.notAvailableOnMobile}>
                Phone Consult Schedule Editing is not available on mobile
                devices. Please visit this page on a desktop or laptop PC to
                edit your phone consult schedule.
              </Alert>
            )}
          </div>
        )}
        <div
          className={cx({ [classes.hidden]: tab !== ScheduleTabs.unavailable })}
        >
          <SpecialistVacation
            hasPhoneConsultPermission={phoneConsultGranted}
            height={height}
          />
        </div>
        <div
          className={cx({
            [classes.hidden]: tab !== ScheduleTabs.customCalendar,
          })}
        >
          <CustomCalendar />
        </div>
        <div
          className={cx({
            [classes.hidden]: tab !== ScheduleTabs.consultLimit,
          })}
        >
          <ConsultLimit />
        </div>
      </HeightLayoutChild>
    </HeightLayout>
  );
};

export default EditSchedulePage;
