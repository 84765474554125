import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import { FC, ReactNode } from "react";

interface SnackbarMessageProps {
  message?: ReactNode;
  useButton?: boolean;
  buttonText?: string;
  show: boolean;
  setShow: (show: boolean) => void;
  onClick?: () => void;
}

const SnackbarMessage: FC<SnackbarMessageProps> = ({
  message,
  useButton,
  buttonText,
  show,
  setShow,
  onClick,
}) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={show}
      autoHideDuration={6000}
      onClose={() => {
        setShow(false);
      }}
      message={message}
      action={
        <>
          {useButton !== undefined && (
            <Button color="secondary" size="small" onClick={onClick}>
              {buttonText}
            </Button>
          )}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              setShow(false);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};

export default SnackbarMessage;
