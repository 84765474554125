import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import {
  SyntheticEvent,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { Control, useWatch } from "react-hook-form";
import { Activity, Location } from "../../../../../shared/types";
import { AuthContext } from "../../../../AuthProvider";
import analyticsLogs from "../../../../analyticsLogs";
import { logAnalyticsEvent } from "../../../../firebase";
import { usePermissionsContext } from "./PermissionsContext";
import { ServiceItem } from "./serviceHelpers";
import {
  searchBySpecialtySubsite,
  searchByString,
} from "./serviceSearchFunctions";

// A "sub" controller of ConsultFormController
export interface ServiceSearchControl {
  locationIdx: Activity.ConsultFormFields["locationIdx"];
  specialty: Activity.ConsultFormFields["specialty"];
  subsite: Activity.ConsultFormFields["subsite"];
  serviceId: Activity.ConsultFormFields["serviceId"];
  searchType: Activity.ConsultFormFields["searchType"];
}

export interface ServiceSearchControllerProps {
  selectedService: ServiceItem | null;
  control: Control<ServiceSearchControl>;
  selectService: (service: ServiceItem) => void;
  changeSearchType: (
    searchType: Activity.ConsultFormFields["searchType"],
  ) => void;
  deselectService: () => void;
  clearSpecialtyAndSubsite: () => void;
  resetSubsite: () => void;
}

const ServiceSearchController = ({
  selectedService,
  control,
  selectService,
  changeSearchType,
  deselectService,
  clearSpecialtyAndSubsite,
  resetSubsite,
}: ServiceSearchControllerProps) => {
  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState<ServiceItem[]>([]);
  const [moreResultsAvailable, setMoreResultsAvailable] =
    useState<boolean>(true);
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });
  const authContext = useContext(AuthContext);
  const { permissions } = usePermissionsContext();
  const tab = useWatch({
    control,
    name: "searchType",
    defaultValue: "directory",
  });

  // GETTING REFERRAL USER'S GEO LOCATION
  const locationIdx = useWatch({
    control,
    name: "locationIdx",
    defaultValue: 0,
  });
  const location = authContext?.profile?.locations[locationIdx] as Location;

  const handleChangeTab = useCallback(
    (_: SyntheticEvent, newValue: Activity.ConsultFormFields["searchType"]) => {
      // Deselect service and clear search results when switching tabs to prevent old data from showing in weird ways between search and directory
      deselectService();
      // Clear specialty and subsite so that services in search don't try and populate directory dropdowns with incorrect values
      clearSpecialtyAndSubsite();
      setSearchResults([]);
      setMoreResultsAvailable(true);
      changeSearchType(newValue);
      logAnalyticsEvent(
        newValue === "search"
          ? analyticsLogs.services.clickSearchBar
          : analyticsLogs.services.clickDirectory,
      );
    },
    [
      deselectService,
      setSearchResults,
      setMoreResultsAvailable,
      changeSearchType,
    ],
  );

  // Define a ref to store the current AbortController (used to abort on-going searches)
  const abortControllerRef = useRef(new AbortController());

  const stringSearch = useCallback(
    (searchText: string) => {
      // If there's an ongoing search, cancel it
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      //? Even if there isn't a search query we want to abort the current on-going search if there is one
      if (!searchText) {
        setProcessState(ProcessState.idle);
        return;
      }

      // Create a new AbortController for the new search
      abortControllerRef.current = new AbortController();
      const { signal } = abortControllerRef.current;

      setProcessState(ProcessState.running);
      logAnalyticsEvent(analyticsLogs.services.performSearchBarSearch);

      //   TODO: modify passing permissions so that specialty permissions are grabbed from firebase
      return searchByString({
        location: location,
        typesenseServicesSearchApiKey:
          authContext.typesenseServicesSearchApiKey,
        searchText: searchText,
        // documentsPerPage can be 250 max (https://typesense.org/docs/27.1/api/search.html#pagination-parameters)
        paginationParameters: { pageNumber: 0, documentsPerPage: 50 },
        permissions: permissions,
      })
        .then((results) => {
          // Check if the request was aborted to avoid updating state
          if (!signal.aborted) {
            setSearchResults(results);
            setMoreResultsAvailable(results.length > 0);
            setProcessState(ProcessState.idle);
          }
        })
        .catch((error: Error) => {
          if (!signal.aborted) {
            errorHandler({
              error,
              userMessage: "Error searching for services",
              analyticsLog: analyticsLogs.services.error.searchBarSearch,
            });
          }
        });
    },
    [
      setSearchResults,
      setMoreResultsAvailable,
      setProcessState,
      errorHandler,
      authContext.typesenseServicesSearchApiKey,
      location,
    ],
  );

  const handleStringSearch = useCallback(
    (searchText: string) => {
      // setSearchResults([]) (makes the cont. searching look smoother)
      stringSearch(searchText); // setSearchResult() is called within stringSearch()
    },
    [setSearchResults, stringSearch],
  );

  const handleRestartSearchResults = useCallback(() => {
    setSearchResults([]);
  }, [setSearchResults, stringSearch]);

  const handleDirectorySearch = (specialty: string, subsite: string) => {
    // Clear list
    setSearchResults([]);

    // If either specialty or subsite is empty, don't search
    if (specialty === "" || subsite === "") return;

    setProcessState(ProcessState.running);
    logAnalyticsEvent(analyticsLogs.services.performDirectorySearch);

    return searchBySpecialtySubsite({
      location,
      typesenseServicesSearchApiKey:
        authContext.typesenseServicesSearchApiKey ?? "",
      specialty,
      subsite,
      permissions,
      // documentsPerPage can be 250 max (https://typesense.org/docs/27.1/api/search.html#pagination-parameters)
      paginationParameters: { pageNumber: 0, documentsPerPage: 50 },
    })
      .then((results) => {
        setSearchResults(results);
        setProcessState(ProcessState.idle);
      })
      .catch((error: Error) => {
        errorHandler({
          error,
          userMessage: "Error searching for services",
          analyticsLog: analyticsLogs.services.error.directorySearch,
        });
      });
  };

  const handleSearchText = (searchText: string) => {
    setSearchText(searchText);
  };

  const handleSelectService = (serviceId: string) => {
    const service = searchResults.find((service) => service.id === serviceId);
    if (service) selectService(service);
  };

  const handleDeselectService = () => {
    deselectService();
  };

  const handleResetSubsite = () => {
    resetSubsite();
  };

  return {
    searchText, // the search query variable
    handleSearchText, // setting the search query variable
    selectedService,
    control,
    searchResults, // the results that we get back & render
    moreResultsAvailable,
    processState,
    processErrorMessage,
    tab,
    handleChangeTab,
    handleSelectService,
    handleStringSearch, // the button click that triggers the search
    handleRestartSearchResults,
    handleDirectorySearch,
    handleDeselectService,
    handleResetSubsite,
  };
};

export default ServiceSearchController;
