import { SpecialistAvailability } from "@alethea-medical/aletheamd-types";
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { FC } from "react";
import { PeriodName } from "./types";

type ConsultLimitPeriodDisplayProps = {
  enabled: boolean;
  onChecked: (value: boolean) => void;
  onChange: (value: string) => void;
  period: SpecialistAvailability.Period;
  limit: string;
};

const ConsultLimitPeriodDisplay: FC<ConsultLimitPeriodDisplayProps> = ({
  enabled,
  onChecked,
  onChange,
  period,
  limit,
}) => {
  return (
    <Box sx={{ px: 1 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={enabled}
            onChange={(e) => onChecked(e.target.checked)}
            name={"hasConsultLimit"}
          />
        }
        label={`Turn on ${PeriodName[period]} Consult Limit`}
      />
      <Collapse in={enabled}>
        <Box display="flex" alignItems="center">
          <InputLabel sx={{ pr: 1 }} htmlFor="consultLimit">
            Number of eConsults {PeriodName[period]}
          </InputLabel>
          <TextField
            value={limit}
            type="text"
            variant="standard"
            sx={{ display: "inline-flex" }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">#:</InputAdornment>
                ),
              },
              htmlInput: {
                inputMode: "numeric",
                pattern: "[0-9]*",
              },
            }}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onInput={(e) => {
              const input = e.target as HTMLInputElement;
              input.value = input.value.replace(/[^0-9]/g, "");
            }}
            name={"consultLimit"}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default ConsultLimitPeriodDisplay;
