import {
  PaperModal,
  ProcessState,
  ProcessStatus,
  thinScrollbar,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { dbNames, resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import { Theme } from "@mui/material/styles";
import firebase from "firebase/compat/app";
import React, { FC, useContext, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { AuthContext } from "../../../../AuthProvider";
import { fbFunctions, logAnalyticsEvent } from "../../../../firebase";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import LeavePagePrompt from "../../../../components/LeavePagePrompt";

import analyticsLogs from "../../../../analyticsLogs";
import { UserMediaMetadataSelectedDict } from "../../../../components/Gallery/Controllers/SelectGalleryController";
import { FileDict } from "../../../../components/useFileDrop/useFileDrop";
import usePermissions from "../../../../components/usePermissions";
import { InputContext } from "../Utilities/InputProvider";
import { uploadFilesToEconsult } from "./Models/MessageInputModel";
import UploadSecureMessageFiles from "./UploadSecureMessageFiles";

import { getDocumentData } from "@alethea-medical/utilities";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import { Activity } from "../../../../../shared/types";
import DropdownMenuButton from "../../../../components/DropdownMenuButton";
import { DropdownMenuItem } from "../../../../components/DropdownMenuButton/DropdownMenuButton";
import { fbFirestore } from "../../../../firebase";
import BillingCodeTextField from "../../BillingCodes/BillingCodeTextField";
import { ActivityContext } from "../Inbox/Contexts/ActivityProvider";

const useStyles = makeStyles()((theme: Theme) => ({
  ...thinScrollbar,
  inputProps: {
    minHeight: "20px",
  },
  messageInputBox: {
    position: "relative",
    [`& fieldset`]: {
      borderRadius: "15px",
    },
    billingCodeInputBox: {
      marginTop: "0px",
      paddingRight: "5px",
    },
    inputProps: {
      minHeight: "20px",
    },
    modalContainer: {
      padding: theme.spacing(1),
      overflowY: "auto",
      width: "100%",
      height: "100%",
    },
    iconButton: {
      marginRight: theme.spacing(1),
    },
    galleryItem: {
      marginBottom: theme.spacing(1),
    },
  },
}));

interface MessageInputProps {
  viewType: "md" | "moa";
  clearMessageDraft?: () => void;
}

const MessageInput: FC<MessageInputProps> = ({
  viewType,
  clearMessageDraft,
}) => {
  const { classes, cx } = useStyles();

  const authContext = useContext(AuthContext);
  const inputContext = useContext(InputContext);
  const activityContext = useContext(ActivityContext);

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });
  const [showUploadModal, setShowUploadModal] = useState(false);

  const [billMessages, setBillMessages] = useState(true);
  const [billingCodeErrorMessage, setBillingCodeErrorMessage] = useState("");

  const { granted: billingCustomizationGranted } = usePermissions({
    resourceKey: resourceKeys.billingCodeCustomization,
  });

  const defaultBillingCode = "03.01NG";

  useEffect(() => {
    if (billingCustomizationGranted) {
      if (
        inputContext.billingCode.length > 0 &&
        inputContext.billingCode.length < 4
      ) {
        setBillingCodeErrorMessage(
          "Billing code must be at least 4 characters long",
        );
      } else {
        setBillingCodeErrorMessage("");
      }
    }
  }, [inputContext.billingCode]);

  const addSelfToActivityAsMoa = fbFunctions.httpsCallable(
    "activity-addSelfToActivityAsMoa_v1",
  );

  // Check if there is a message draft, load it in
  useEffect(() => {
    fbFirestore
      .collection(dbNames.userActivities)
      .doc(authContext.uid)
      .collection(dbNames.userActivities_activities)
      .doc(activityContext.activityId)
      .get()
      .then(getDocumentData)
      .then((activity: Activity.UserActivity) => {
        if (activity.messageDraft) {
          inputContext.setSavedValue(activity.messageDraft);
          inputContext.setValue(activity.messageDraft);
          // Set form not dirty
          inputContext.setFormDirty(false);
        }
      });
  }, []);

  const addUserIfMoa = async () => {
    if (
      viewType === "moa" &&
      !activityContext.sharedActivity.users.includes(authContext.uid)
    ) {
      setProcessState(ProcessState.running);
      await addSelfToActivityAsMoa({
        activityId: activityContext.activityId,
      });
      return await Promise.resolve();
    }
    return Promise.resolve();
  };

  const onSendPressed = async () => {
    inputContext.setValue("");
    inputContext.setSavedValue("");
    inputContext.setBillingCode("");
    inputContext.setFormDirty(false);
    if (clearMessageDraft) {
      clearMessageDraft();
    }

    const newMessage: Activity.Message = {
      sentBy: authContext.uid,
      sentAt: firebase.firestore.Timestamp.now(),
      message: inputContext.value,
      billable: billMessages,
      billingCode: billingCustomizationGranted
        ? inputContext.billingCode
          ? inputContext.billingCode
          : defaultBillingCode
        : "",
    };

    try {
      await addUserIfMoa();
      await activityContext.sendMessage(newMessage);
      setProcessState(ProcessState.idle);
      logAnalyticsEvent(analyticsLogs.secureMessaging.message.send);
      inputContext.onMessageSent();
    } catch (error) {
      errorHandler({
        error: error as Error,
        userMessage: "Error sending message. Please try again",
        analyticsLog: analyticsLogs.secureMessaging.message.sendFail,
      });
    }
  };

  const uploadFileHandler = async (
    files: FileDict,
    selectedMedia: UserMediaMetadataSelectedDict,
  ) => {
    setProcessState(ProcessState.running);
    logAnalyticsEvent("secure_message_upload_files_start");
    const numToUpload =
      Object.keys(files).length + Object.keys(selectedMedia).length;

    /** Upload to storage */
    try {
      await addUserIfMoa();
      const {
        uploadURIs,
        uploadFilenames,
        successFileUploadKeys,
        successGalleryUploadKeys,
      } = await uploadFilesToEconsult(files, selectedMedia);
      const newReferralMediaURIs = [
        ...activityContext.econsult.referralMediaURIs,
        ...uploadURIs,
      ];
      await activityContext.updateEconsult({
        referralMediaURIs: newReferralMediaURIs,
      });
      await activityContext.sendMessage({
        sentBy: authContext.uid,
        sentAt: firebase.firestore.Timestamp.now(),
        message: `Uploaded: ${uploadFilenames.join(", ")}`,
        messageAction: true,
        messageActionData: {
          messageActionType: "attachFile",
          fileUris: uploadURIs,
        },
        billable: billMessages,
      });
      const {
        successFileUploadKeys: successFileUploadKeys_1,
        successGalleryUploadKeys: successGalleryUploadKeys_1,
      } = { successFileUploadKeys, successGalleryUploadKeys };
      const numUploaded =
        successFileUploadKeys_1.length + successGalleryUploadKeys_1.length;
      if (numUploaded === numToUpload) {
        logAnalyticsEvent("secure_message_upload_files_success");
        setProcessState(ProcessState.success);
        setTimeout(() => {
          setProcessState(ProcessState.idle);
        }, 2000);
      } else {
        const errorMessage = `${
          numToUpload - numUploaded
        } out of ${numToUpload} files failed to upload. Please try uploading these files again.`;
        errorHandler({
          error: new Error(errorMessage),
          userMessage: errorMessage,
          analyticsLog: "secure_message_upload_files_failed",
          hideErrorMessage: true,
        });
      }
      return { successFileUploadKeys, successGalleryUploadKeys };
    } catch (error) {
      errorHandler({
        error: error as Error,
        userMessage: "Error uploading files. Please try again",
        analyticsLog: "secure_message_upload_files_failed",
      });
      return undefined;
    }
  };

  const keydownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isSendDisabled() && e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
      onSendPressed();
    }
  };

  const isSendDisabled = () => {
    return (
      (billingCustomizationGranted &&
        inputContext.billingCode.length > 0 &&
        inputContext.billingCode.length < 4) ||
      inputContext.value.length === 0 ||
      processState === ProcessState.running
    );
  };
  const isTextDisabled = () => {
    return processState === ProcessState.running;
  };

  const toggleBillingHandler = () => {
    logAnalyticsEvent(
      billMessages
        ? analyticsLogs.secureMessaging.toggleBillOff
        : analyticsLogs.secureMessaging.toggleBillOn,
    );

    setBillMessages(!billMessages);
  };

  const { granted: billingGranted } = usePermissions({
    resourceKey: resourceKeys.billing,
  });

  // Show leave page prompt if form is dirty (most cases)
  // If the form value contains empty string, then there is no point in showing the prompt
  const shouldShowLeavePagePrompt =
    inputContext.formDirty && inputContext.value !== inputContext.savedValue;

  return (
    <>
      <Grid container alignItems={"flex-end"}>
        <Grid>
          <DropdownMenuButton
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            transformOrigin={{ vertical: "bottom", horizontal: "center" }}
            buttonIcon={<MoreHorizIcon />}
            autoClose={false}
          >
            {billingGranted && (
              <DropdownMenuItem
                label={billMessages ? "Bill Messages" : "Do Not Bill Messages"}
                onClick={toggleBillingHandler}
                color={"primary"}
                style={{ minWidth: "250px" }}
                icon={
                  <Switch
                    checked={billMessages}
                    onChange={toggleBillingHandler}
                  />
                }
              />
            )}
            <DropdownMenuItem
              label="Upload Files"
              onClick={() => {
                setShowUploadModal(true);
              }}
              icon={
                <AttachFileIcon
                  style={{ marginLeft: "14px" }}
                  color="primary"
                />
              }
              color="primary"
            />
          </DropdownMenuButton>
        </Grid>
        <Grid size={{ xs: "grow" }}>
          <Grid container alignItems="center">
            {billingCustomizationGranted && (
              <Grid size={{ xs: 6, sm: 4 }}>
                <BillingCodeTextField
                  value={inputContext.billingCode}
                  onChange={inputContext.setBillingCode}
                  disabled={isTextDisabled()}
                  defaultValue={defaultBillingCode}
                />
              </Grid>
            )}
            <Grid size={{ xs: 8 }}>
              <Typography variant="caption" style={{ color: "red" }}>
                {billingCodeErrorMessage}
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <TextField
              className={cx(classes.thinScrollbar, classes.messageInputBox)}
              value={inputContext.value}
              onChange={(e) => inputContext.setValue(e.target.value)}
              onKeyDown={keydownHandler}
              variant="outlined"
              fullWidth
              multiline
              margin="dense"
              placeholder="Type a message"
              disabled={isTextDisabled()}
              InputProps={{ classes: { input: classes.inputProps } }}
            />
          </Grid>
        </Grid>
        <Grid>
          <Tooltip title="Ctrl/Cmd + Enter to send">
            <span>
              <IconButton
                disabled={isSendDisabled()}
                onClick={onSendPressed}
                size="large"
              >
                <SendIcon color={isSendDisabled() ? undefined : "primary"} />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <PaperModal show={showUploadModal} setShow={setShowUploadModal}>
        <UploadSecureMessageFiles
          processState={processState}
          processErrorMessage={processErrorMessage}
          uploadHandler={uploadFileHandler}
        />
      </PaperModal>
      <ProcessStatus
        state={processState}
        setState={setProcessState}
        errorMessage={processErrorMessage}
        loadingMessage="Sending message..."
        useSnackbar
        hideProgressBar
      />
      <LeavePagePrompt isDirty={shouldShowLeavePagePrompt} />
    </>
  );
};

export default MessageInput;
