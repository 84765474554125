import CloseIcon from "@mui/icons-material/Close";
import { MenuItem } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { FC } from "react";
import isNativeMobile from "../../../../models/isNativeMobile";
import isWeb from "../../../../models/isWeb";
import MediaEditTagsController from "../../Controllers/MediaEditTagsController";

interface MediaEditTagsProps {
  tags: string[];
  tagsChanged: (tags: string[]) => void;
  placeholder?: string;
  saveNewTags?: boolean;
}

const MediaEditTags: FC<MediaEditTagsProps> = ({
  tags,
  tagsChanged,
  placeholder,
  saveNewTags = true,
}) => {
  const { userTags, updateTagsHandler, deleteTagHandler } =
    MediaEditTagsController();

  const getHelperText = (inputValue: string, hasFocus: boolean) => {
    //If the textfield value could exist in the list, don't show helper text
    //If not focused, don't show helper text
    if (
      !hasFocus ||
      inputValue.length === 0 ||
      userTags.some((t) => t.toLowerCase().includes(inputValue.toLowerCase()))
    )
      return undefined;

    //If no results showing, show helper text to add a new tag
    return "Press enter to add a new tag";
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      autoSelect={isNativeMobile()}
      options={userTags}
      value={tags}
      clearOnBlur
      filterSelectedOptions
      onChange={(e, value) => {
        tagsChanged(value as string[]);
        if (saveNewTags) updateTagsHandler(value as string[]);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={tags.length > 0 ? "" : placeholder}
          variant="outlined"
          size="small"
          helperText={
            isWeb() &&
            getHelperText(
              (params?.inputProps as any)?.value,
              document.activeElement ===
                (params?.inputProps as any)?.ref?.current,
            )
          }
          slotProps={{
            htmlInput: {
              ...params.inputProps,
              enterKeyHint: "done", //Allow enter to add tag on mobile
            },
          }}
        />
      )}
      renderOption={(_, option) => {
        return (
          <MenuItem
            sx={{ justifyContent: "space-between" }}
            onClick={() => {
              if (!tags.includes(option)) {
                const newTags = [...tags, option];
                tagsChanged(newTags);
                if (saveNewTags) updateTagsHandler(newTags);
              }
            }}
          >
            {option}
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                deleteTagHandler(option);
              }}
              size="small"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </MenuItem>
        );
      }}
    />
  );
};

export default MediaEditTags;
