import { UserProfile } from "@alethea-medical/aletheamd-types";
import { useContext, useEffect, useState } from "react";
import { PermissionAppRoute } from "src/types/AppRoute";
import { AuthContext } from "../../AuthProvider";
import hasPermissions from "../../models/hasPermissions";
import isMobileDevice from "../../models/isMobileDevice";

// Returns
const userMatchesResourcesArray = async (
  resourcesArray: string[][],
  userProfile: UserProfile,
): Promise<boolean> => {
  const userHasPermissionsArray = await Promise.all(
    resourcesArray.map(
      async (resources) =>
        await Promise.all(
          resources.map((resource) => hasPermissions(resource, userProfile)),
        ),
    ),
  );
  return userHasPermissionsArray.some((permissions) =>
    permissions.every((permission) => permission),
  );
};

const useRoutePermissions = (route?: PermissionAppRoute) => {
  const authContext = useContext(AuthContext);

  const [permissionGranted, setPermissionGranted] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkPermission = async (
    route: PermissionAppRoute,
  ): Promise<boolean> => {
    if (
      (isMobileDevice() && !route?.availableOnMobile) ||
      authContext.profile === undefined
    )
      return false;
    return (
      (await userMatchesResourcesArray(
        route.requiredResourcePermissions,
        authContext.profile,
      )) &&
      (route.antirequisiteResourcePermissions === undefined
        ? true
        : !(await userMatchesResourcesArray(
            route.antirequisiteResourcePermissions,
            authContext.profile,
          )))
    );
  };

  useEffect(() => {
    if (route === undefined || authContext.loading) return;
    setLoading(true);
    checkPermission(route)
      .then((allow) => {
        setPermissionGranted(allow);
      })
      .finally(() => setLoading(false));
  }, [route, authContext.profile, authContext.loading]);

  return {
    permissionGranted,
    loading,
  };
};

export default useRoutePermissions;
