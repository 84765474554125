import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { SpecialistAvailability } from "@alethea-medical/aletheamd-types";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useCallback, useContext, useEffect, useState } from "react";
import { dbNames } from "shared/db-keys/dist";
import { AuthContext } from "src/AuthProvider";
import HelpModal from "src/components/HelpModal";
import { ProcessStatus } from "src/components/ProcessState";
import SaveDiscardToolbar from "src/components/SaveDiscardToolbar";
import { fbFirestore, logAnalyticsEvent } from "src/firebase";
import ConsultLimitPeriodDisplay from "./ConsultLimitPeriodDisplay";

type ConsultLimitsState = {
  [key in keyof SpecialistAvailability.ConsultLimits]: Omit<
    SpecialistAvailability.ConsultLimits[key],
    "limit"
  > & { limit: string };
};

export const defaultConsultLimits: ConsultLimitsState = {
  [SpecialistAvailability.Periods.daily]: { enabled: false, limit: "" },
  // [Periods.weekly]: { enabled: false, limit: "" },
  // [Periods.monthly]: { enabled: false, limit: "" },
};

// Returns true if the consult limit object is valid to be saved
const isConsultLimitSubmittable = (data: ConsultLimitsState): boolean =>
  Object.values(data).every(({ limit }) => {
    const numericalValue = parseInt(limit);
    return !isNaN(numericalValue) && numericalValue > 0;
  });

// Returns true if all the limits in the entire ConsultLimit Type are equal
const areConsultLimitsStatesEqual = (
  a: ConsultLimitsState,
  b: ConsultLimitsState,
): boolean => {
  const keysA = Object.keys(a) as SpecialistAvailability.Period[];
  const keysB = Object.keys(b) as SpecialistAvailability.Period[];

  if (keysA.length !== keysB.length) {
    return false;
  }

  return keysA.every((key) => {
    return (
      key in b &&
      ((!a[key].enabled && !b[key].enabled) ||
        (a[key].enabled && b[key].enabled && a[key].limit === b[key].limit))
    );
  });
};

// Returns true if the data is a partial ConsultLimits object (e.g. { daily: { enabled: true, limit: 5 } })
// Partial in the sense that it doesn't have to include all the periods
const isLoadableValue = (data: any): boolean => {
  return (
    typeof data === "object" &&
    Object.keys(data).some((key) => {
      const { enabled, limit } = data[key];
      return (
        typeof enabled === "boolean" &&
        typeof limit === "number" &&
        Object.values(SpecialistAvailability.Periods).includes(key as any)
      );
    })
  );
};

const ConsultLimit = () => {
  const authContext = useContext(AuthContext);
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  // Consult limits from firebase, if saved in firebase already
  const [savedConsultLimits, setSavedConsultLimits] =
    useState<ConsultLimitsState>(defaultConsultLimits);
  // Consult limits that are currently being edited/shown in the frontend
  const [currentConsultLimits, setCurrentConsultLimits] =
    useState<ConsultLimitsState>(defaultConsultLimits);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  // fetch data from firebase on load
  useEffect(() => {
    setProcessState(ProcessState.running);
    fbFirestore
      .collection(dbNames.specialistAvailabilitySettings)
      .doc(authContext.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          console.log(data?.consultLimits);
          if (isLoadableValue(data?.consultLimits)) {
            const loadedConsultLimits = {
              ...defaultConsultLimits,
              ...Object.fromEntries(
                Object.keys(data?.consultLimits).map((key) => [
                  key,
                  {
                    ...data?.consultLimits[key],
                    limit: data?.consultLimits[key].limit.toString(),
                  },
                ]),
              ),
            };
            setSavedConsultLimits(loadedConsultLimits);
            setCurrentConsultLimits(loadedConsultLimits);
          }
        }
      })
      .catch(errorHandler)
      .then(() => {
        setProcessState(ProcessState.success);
      });
  }, [
    authContext.uid,
    dbNames.specialistAvailabilitySettings,
    setSavedConsultLimits,
    setCurrentConsultLimits,
    setProcessState,
  ]);

  // Saves the current consult limits to firebase with the limits as numbers
  const saveCustomLimit = useCallback(async () => {
    setProcessState(ProcessState.running);
    if (!isConsultLimitSubmittable(currentConsultLimits)) {
      setProcessState(ProcessState.error);
      return;
    }
    await fbFirestore
      .collection(dbNames.specialistAvailabilitySettings)
      .doc(authContext.uid)
      .set(
        {
          consultLimits: Object.fromEntries(
            Object.keys(currentConsultLimits).map((key) => [
              key,
              {
                ...currentConsultLimits[key as SpecialistAvailability.Period],
                limit: parseInt(
                  currentConsultLimits[key as SpecialistAvailability.Period]
                    .limit,
                ),
              },
            ]),
          ),
        },
        { merge: true },
      );
    setIsDirty(false);
    setProcessState(ProcessState.success);
  }, [
    authContext.uid,
    dbNames.specialistAvailabilitySettings,
    setIsDirty,
    setProcessState,
    currentConsultLimits,
  ]);

  const discardCustomLimit = useCallback(() => {
    setCurrentConsultLimits(savedConsultLimits);
    setIsDirty(false);
  }, [savedConsultLimits, setCurrentConsultLimits, setIsDirty]);

  return (
    <Box sx={{ padding: 1 }}>
      <SaveDiscardToolbar
        sx={{ my: 1 }}
        show={isDirty}
        modalText={"Discard changes to your consult limit settings?"}
        saveHandler={saveCustomLimit}
        discardHandler={discardCustomLimit}
        unsavedText="Unsaved Settings"
      />
      <ProcessStatus
        sx={{ my: 1 }}
        state={processState}
        errorMessage={processErrorMessage}
      />
      <Typography>
        Set a daily limit for new eConsults
        <Box
          component={"span"}
          sx={{
            display: "inline-flex",
            verticalAlign: "middle",
            marginLeft: 1,
          }}
        >
          <HelpModal
            helpText={[
              `Once you reach your set limit, your account will be marked as unavailable until midnight. This does not affect messages on existing consults, which won't impact your average response time. You can reply whenever you're available.`,
            ]}
          />
        </Box>
      </Typography>

      {Object.values(SpecialistAvailability.Periods).map((period) => {
        return (
          <ConsultLimitPeriodDisplay
            enabled={currentConsultLimits[period].enabled}
            onChecked={(isChecked) => {
              setCurrentConsultLimits((prevLimits) => {
                const newLimits = {
                  ...prevLimits,
                  [period]: {
                    ...prevLimits[period],
                    enabled: isChecked,
                  },
                };
                setIsDirty(
                  isConsultLimitSubmittable(newLimits) &&
                    !areConsultLimitsStatesEqual(newLimits, savedConsultLimits),
                );
                return newLimits;
              });
            }}
            onChange={(value) => {
              setCurrentConsultLimits((prevLimits) => {
                const newLimits = {
                  ...prevLimits,
                  [period]: {
                    ...prevLimits[period],
                    limit: value,
                  },
                };
                setIsDirty(
                  isConsultLimitSubmittable(newLimits) &&
                    !areConsultLimitsStatesEqual(newLimits, savedConsultLimits),
                );
                return newLimits;
              });
            }}
            limit={currentConsultLimits[period].limit}
            period={period}
          />
        );
      })}
    </Box>
  );
};

export default ConsultLimit;
