import {
  ProcessState,
  ProcessStatus,
} from "@alethea-medical/alethea-components";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import { Location, UserProfile } from "@alethea-medical/aletheamd-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import { FC, useEffect, useMemo, useState } from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import { TypedFormCheckbox } from "src/components/FormInputFields/FormCheckbox";
import FormProvinceSelect from "src/components/FormInputFields/FormProvinceSelect";
import { TypedFormSelectAutocomplete } from "src/components/FormInputFields/FormSelectAutocomplete";
import { TypedFormTextField } from "src/components/FormInputFields/FormTextField";
import { TypedPhoneInput } from "src/components/FormInputFields/PhoneInput";
import FormattedInputLabel from "src/components/FormattedInputLabel";
import usePermissions from "src/components/usePermissions";
import useCms from "src/utils/useCms";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  clinic: {
    padding: theme.spacing(1),
  },
}));

interface ProfileLocationsProps {
  control: Control<UserProfile>;
  setValue: UseFormSetValue<Partial<UserProfile>>;
}

const ProfileLocations: FC<ProfileLocationsProps> = ({ control, setValue }) => {
  const { classes } = useStyles();

  // Proxy for checking if someone is a resident (which is a role)
  // We see that everyone but residents have the econsultAndSecureMessaging Permission
  const { granted: canCompleteBillableEvents, loading } = usePermissions({
    resourceKey: resourceKeys.econsultAndSecureMessaging,
  });

  // Get the form values
  const initialLocations = useWatch({
    control,
    name: "locations",
    defaultValue: [],
  });

  // Turn locations into a state so it can be modified
  const [locations, setLocations] = useState<Location[]>([]);

  // Set the locations once they are loaded in
  useEffect(() => {
    setLocations(initialLocations);
  }, [initialLocations]);

  const emrContent = useCms({ cmsId: "emrs" });

  const addNewClinic = () => {
    const newClinic: Location = {
      clinicName: "",
      clinicPhone: "",
      emr: "",
      fax: "",
      city: "",
      province: locations[0].province || "", // Has to have a province, so default to first location initially
      billable: true,
      disabled: false,
    };

    const updatedLocations = [...locations, newClinic];
    setLocations(updatedLocations);
    setValue("locations", updatedLocations); // Update the form values
  };

  // Ensure only 1 location is set to default
  const handleDefaultChange = (index: number) => {
    const updatedLocations = locations.map((loc, i) => ({
      ...loc,
      default: i === index ? !loc.default : false,
    }));

    setLocations(updatedLocations);
    setValue("locations", updatedLocations);
  };

  if (loading) {
    return <ProcessStatus state={ProcessState.running} />;
  }

  return (
    <Grid container spacing={1}>
      <Grid size={{ xs: 12 }}>
        <FormattedInputLabel>Your Clinics</FormattedInputLabel>
      </Grid>
      {locations.map(
        (loc: Location, index: number) =>
          !loc.disabled && (
            <Grid size={{ xs: 12 }} key={`location_${index}`}>
              <Paper elevation={1} className={classes.clinic}>
                <Grid container>
                  <Grid size={{ xs: 12 }}>
                    <TypedFormTextField
                      name={`locations.${index}.clinicName`}
                      control={control}
                      label="Clinic Name"
                      rules={{
                        required: {
                          value: true,
                          message: "Clinic name is required",
                        },
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <TypedPhoneInput
                      name={`locations.${index}.clinicPhone`}
                      control={control}
                      label="Clinic Phone Number"
                      rules={{
                        required: {
                          value: true,
                          message: "Clinic phone number is required",
                        },
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <TypedFormSelectAutocomplete
                      name={`locations.${index}.emr`}
                      control={control}
                      label="EMR"
                      options={emrContent.getArray<string>("emrList")}
                      freeSolo
                      autoSelect
                    />
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <TypedPhoneInput
                      name={`locations.${index}.fax`}
                      control={control}
                      label="Fax Number"
                      rules={{
                        required: {
                          value: true,
                          message: "Fax number is required",
                        },
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <TypedFormTextField
                      name={`locations.${index}.city`}
                      control={control}
                      label="City"
                    />
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <FormProvinceSelect
                      name={`locations.${index}.province`}
                      control={control as any}
                      label="Province"
                      enabledOptions={["BC", "AB", "SK", "YT"]}
                      sortByEnabled={true}
                      rules={{
                        required: {
                          value: canCompleteBillableEvents,
                          message: "Clinic province is required",
                        },
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <TypedFormCheckbox
                      name={`locations.${index}.default`}
                      control={control}
                      label="Default Clinic"
                      onChange={() => handleDefaultChange(index)}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ),
      )}

      <Grid size={{ xs: 12 }}>
        <Button variant="outlined" color="primary" onClick={addNewClinic}>
          Add Clinic
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProfileLocations;
