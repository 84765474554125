import { ProcessState } from "@alethea-medical/alethea-components";
import { useEffect, useState } from "react";
import useExternalScript from "../../../components/useExternalScript";
import devConsole from "../../../models/devConsole";
import { cloverApiKey, cloverSdkUrl } from "./cloverKeys";

export type CloverInputType =
  | "CARD_NUMBER"
  | "CARD_DATE"
  | "CARD_CVV"
  | "CARD_POSTAL_CODE";
type CloverErrorDict = {
  [inputType in CloverInputType]: string;
};
interface Clover {
  createToken: () => Promise<{
    token: string;
    errors: CloverErrorDict | undefined;
  }>;
  elements: () => {
    create: (type: CloverInputType, styles: any) => any;
  };
}

/**
 * This hook loads clover SDK and clover IE 11 polyfills
 * The clover SDK will be available in the global window.clover variable to use for rendering clover iframe elements
 * Make sure to only use the SDK when window.clover is defined
 * */
const useClover = () => {
  // Clover SDK
  const cloverScriptState = useExternalScript({ url: cloverSdkUrl });
  // Polyfills for IE 11. Returns empty script if not needed for browser
  const polyfillScriptState = useExternalScript({
    url: "https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?version=4.8.0",
  });

  const [clover, setClover] = useState<Clover>();

  useEffect(() => {
    // Make sure clover script is loaded, and polyfill script is either loaded or failed loading
    if (
      cloverScriptState !== ProcessState.success ||
      (polyfillScriptState !== ProcessState.success &&
        polyfillScriptState !== ProcessState.error)
    )
      return;

    if ((window as any).clover !== undefined) {
      devConsole.log("Clover is already loaded");
      setClover((window as any).clover);
      return;
    }

    // Make sure clover constructor actually exists before calling it
    const cloverConstructor = (window as any).Clover;
    if (!cloverConstructor) return;

    // Instantiate clover instance
    const newClover: Clover = new cloverConstructor(`${cloverApiKey}`, {
      locale: "en-CA",
    });
    if (!newClover) return;
    devConsole.log("Loaded clover SDK");

    // Set clover to global variable
    (window as any).clover = newClover;
    setClover(newClover);
  }, [cloverScriptState, polyfillScriptState]);

  return clover;
};

export default useClover;
