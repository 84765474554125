import {
  Divider,
  Fade,
  Grid2 as Grid,
  List,
  ListItem,
  ListItemButton,
  Theme,
  Typography,
} from "@mui/material";
import { FC, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "src/AuthProvider";
import { LogoutButton } from "src/views/Auth/components";
import { makeStyles } from "tss-react/mui";

import {
  AppBar,
  thinScrollbar,
  useMobileSizes,
  useSidebar,
} from "@alethea-medical/alethea-components";
import {
  AccountCircle,
  AddCircle,
  Assignment,
  Chat,
  Description,
  Email,
  Event,
  Help,
  Inbox,
  Info,
  LibraryBooks,
  LocalLibrary,
  PhotoCamera,
  PhotoLibrary,
  Toc,
  Visibility,
  WorkspacePremium,
} from "@mui/icons-material";

import analyticsLogs from "src/analyticsLogs";
import OneTimeTooltip from "src/components/OneTimeTooltip";
import AndroidAPKUpdateSidebar from "src/config/AndroidAPKUpdateSidebar";
import { projectEnv } from "src/firebase";
import { logAnalyticsEvent } from "src/firebase";
import isAndroid from "src/models/isAndroid";
import isNativeMobile from "src/models/isNativeMobile";
import palette from "src/palette";
import {
  RouteNames,
  getPathFromRouteName,
  getRouteFromRouteName,
  getRouteHeaderFromPath,
} from "src/routes";
import sharedStyles from "src/sharedStyles";
import versionStrings from "src/versionStrings";
import MobileCameraButton from "src/views/Pages/Camera/MobileCameraButton";
import InviteAColleagueButton from "src/views/Pages/InviteAColleague";
import UnreadNotificationsCounter from "src/views/Pages/SecureMessaging/NotificationCenter/UnreadNotificationsCounter";
import CollapseSidebarItem from "./CollapseSidebarItem";
import ConsultButton from "./ConsultButton";
import SidebarItem from "./SidebarItem";
import AletheaSidebar from "./components/AletheaSidebar";
import AletheaSidebarItem from "./components/AletheaSidebarItem";
const useStyles = makeStyles()((theme: Theme) => ({
  ...sharedStyles(theme),
  thinScrollbar: {
    ...thinScrollbar.thinScrollbar, //Override default thin scrollbar to make it even thinner
    "&::-webkit-scrollbar": {
      width: "0.4em",
      height: "0.4em",
    },
  },
  hide: {
    display: "none",
  },
  header: {
    marginLeft: theme.spacing(2),
  },
  username: {
    whiteSpace: "normal",
  },
  versionString: {
    color: palette.darkGreen,
    fontSize: "0.75em",
  },
}));

const Sidebar: FC = () => {
  const location = useLocation();
  const { classes, cx } = useStyles();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const isMobileSize = useMobileSizes();

  const {
    open,
    setOpen,
    showText,
    drawerWidthOpen,
    drawerWidthClosed,
    renderOpenCloseButtons,
    hideSidebarIfMobile,
  } = useSidebar({
    isMobileSize,
    drawerWidthOpen: 270,
    drawerWidthClosed: 57,
  });

  useEffect(() => {
    if (isMobileSize) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isMobileSize]);

  const showUserName = (): string => {
    const name = `${
      authContext?.profile?.firstName ? authContext.profile.firstName : ""
    } ${authContext?.profile?.lastName ? authContext.profile.lastName : ""}`;
    if (!open) return "";
    if (showText) return name;
    return "";
  };

  return (
    <>
      <AppBar
        className="safeAreaTop safeAreaSides"
        open={open}
        setOpen={setOpen}
        header={getRouteHeaderFromPath(location.pathname)}
        drawerWidthOpen={drawerWidthOpen}
        drawerWidthClosed={drawerWidthClosed}
        isMobileSize={isMobileSize}
        topRightControls={
          isNativeMobile() && (
            <OneTimeTooltip
              tooltipName="mobile_new_camera_button"
              title="Take photos for your eConsult using the camera"
            >
              <MobileCameraButton />
            </OneTimeTooltip>
          )
        }
      />
      <AletheaSidebar
        paperClassName={cx(
          "sidebar",
          "safeAreaTop safeAreaSides safeAreaBottom",
          classes.thinScrollbar,
        )}
        open={open}
        setOpen={setOpen}
        isMobileSize={isMobileSize}
        drawerWidthOpen={drawerWidthOpen}
        drawerWidthClosed={drawerWidthClosed}
      >
        <List color="primary">
          <ListItemButton
            divider
            component={Link}
            to={getPathFromRouteName(RouteNames.notificationCenter)}
            onClick={hideSidebarIfMobile}
          >
            <Grid container direction="row" alignItems="center">
              <Grid style={{ position: "relative" }}>
                <UnreadNotificationsCounter
                  sx={{ position: "absolute", top: 0, right: 0 }}
                />
                <img
                  className={classes.imgFluid}
                  src="/logo_big.png"
                  alt="Alethea Logo"
                  width="40"
                  height="40"
                />
              </Grid>
              {showText && (
                <Grid size={{ xs: 1 }}>
                  <Fade in={showText}>
                    <Typography
                      className={classes.header}
                      variant="h6"
                      color="primary"
                    >
                      Alethea Portal
                    </Typography>
                  </Fade>
                </Grid>
              )}
            </Grid>
          </ListItemButton>
          {isAndroid() && <AndroidAPKUpdateSidebar showText={showText} />}

          <OneTimeTooltip
            tooltipName={"granular_fax_prefs_july_6_2023"}
            header={"Improved Fax Preferences"}
            title={
              <>
                You now have more control over which faxes you receive, and what
                they should contain.
                <br />
                Edit your preferences in your{" "}
                <a href="/dashboard/profile">Profile</a>.
              </>
            }
            placement="right"
          >
            <SidebarItem
              route={getRouteFromRouteName(RouteNames.profile)}
              label={showUserName()}
              textClass={classes.username}
              showText={showText}
              icon={<AccountCircle />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
              {...{ divider: true }}
            />
          </OneTimeTooltip>
          <ConsultButton
            route={getRouteFromRouteName(RouteNames.consult)}
            onClick={hideSidebarIfMobile}
            showText={showText}
            icon={<AddCircle />}
            currentPath={location.pathname}
            linkComponent={Link}
          />
          <Divider />
          <SidebarItem
            route={getRouteFromRouteName(RouteNames.secureMessaging)}
            showText={showText}
            icon={<Chat />}
            currentPath={location.pathname}
            onClick={hideSidebarIfMobile}
            linkComponent={Link}
            {...{ divider: true }}
          />

          <SidebarItem
            route={getRouteFromRouteName(RouteNames.moaConsultInbox)}
            showText={showText}
            icon={<Inbox />}
            currentPath={location.pathname}
            onClick={hideSidebarIfMobile}
            linkComponent={Link}
            {...{ divider: true }}
          />

          <SidebarItem
            route={getRouteFromRouteName(RouteNames.validationRequired)}
            showText={showText}
            icon={<Info />}
            currentPath={location.pathname}
            onClick={hideSidebarIfMobile}
            linkComponent={Link}
            {...{ divider: true }}
          />

          <OneTimeTooltip
            tooltipName={"gallery_ava_integration_feb_17"}
            header={"New Feature for Ava EMR users!"}
            title={
              "You can now upload photos to Ava by selecting photos in your gallery and pressing the 'Upload to Ava' button."
            }
            placement="right"
            visible={authContext?.profile?.integrations?.avaUuid !== undefined}
            actionText={"Go to your Gallery"}
            action={() => {
              navigate("/dashboard/gallery");
            }}
          >
            <CollapseSidebarItem title="Imaging" showCollapse={open}>
              <SidebarItem
                route={getRouteFromRouteName(RouteNames.camera)}
                showText={showText}
                icon={<PhotoCamera />}
                currentPath={location.pathname}
                onClick={hideSidebarIfMobile}
                linkComponent={Link}
              />
              <SidebarItem
                route={getRouteFromRouteName(RouteNames.gallery)}
                showText={showText}
                icon={<PhotoLibrary />}
                currentPath={location.pathname}
                onClick={hideSidebarIfMobile}
                linkComponent={Link}
              />
            </CollapseSidebarItem>
          </OneTimeTooltip>

          <CollapseSidebarItem
            title="Patient Communications"
            showCollapse={open}
          >
            <SidebarItem
              route={getRouteFromRouteName(RouteNames.patientMessages)}
              showText={showText}
              icon={<Inbox />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
            <SidebarItem
              route={getRouteFromRouteName(RouteNames.forms)}
              showText={showText}
              icon={<Assignment />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
            <SidebarItem
              route={getRouteFromRouteName(RouteNames.oneWayMessaging)}
              showText={showText}
              icon={<Email />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
          </CollapseSidebarItem>

          <CollapseSidebarItem title="Specialist Tools" showCollapse={open}>
            <SidebarItem
              route={getRouteFromRouteName(RouteNames.specialistTemplates)}
              showText={showText}
              icon={<Toc />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
            <SidebarItem
              route={getRouteFromRouteName(RouteNames.editResources)}
              showText={showText}
              icon={<Event />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
            <SidebarItem
              route={getRouteFromRouteName(RouteNames.specialistSchedule)}
              showText={showText}
              icon={<LibraryBooks />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
            <SidebarItem
              route={getRouteFromRouteName(RouteNames.imageAnnotation)}
              showText={showText}
              icon={<Visibility />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
          </CollapseSidebarItem>

          <CollapseSidebarItem title="Other" showCollapse={open}>
            <SidebarItem
              route={getRouteFromRouteName(RouteNames.billingReport)}
              showText={showText}
              icon={<Description />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
            <SidebarItem
              route={getRouteFromRouteName(RouteNames.resources)}
              showText={showText}
              icon={<LocalLibrary />}
              currentPath={location.pathname}
              onClick={hideSidebarIfMobile}
              linkComponent={Link}
            />
          </CollapseSidebarItem>

          <SidebarItem
            route={getRouteFromRouteName(RouteNames.getPremium)}
            showText={showText}
            icon={<WorkspacePremium />}
            currentPath={location.pathname}
            onClick={() => {
              logAnalyticsEvent(analyticsLogs.premium.pageVisit);
              hideSidebarIfMobile();
            }}
            linkComponent={Link}
            {...{ divider: true }}
          />

          <InviteAColleagueButton showText={showText} />

          <LogoutButton showText={showText} />

          <AletheaSidebarItem
            label="Support"
            tooltip="Support"
            showText={showText}
            icon={<Help color="primary" />}
            linkComponent="a"
            {...{
              href: "https://aletheamedical.com/support",
              target: "_blank",
              rel: "noreferrer noopener",
              className: "aletheaSidebarItem",
            }}
            {...{ divider: true }}
          />

          {renderOpenCloseButtons()}
          {showText && (
            <ListItem>
              <Typography className={classes.versionString}>
                Version {versionStrings.version}
                {projectEnv === "prod"
                  ? ""
                  : `, Build ${versionStrings.devBuild}`}
              </Typography>
            </ListItem>
          )}
          {showText && projectEnv !== "prod" && (
            <ListItem>
              <Typography className={classes.versionString}>
                Environment: {projectEnv.toUpperCase()}
              </Typography>
            </ListItem>
          )}
        </List>
      </AletheaSidebar>
    </>
  );
};

export default Sidebar;
