import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { FormHelperText } from "@mui/material";
import Button from "@mui/material/Button";
import { FC } from "react";
import useConsultFormStyles from "../useConsultFormStyles";
import ServiceCard from "./ServiceCard";
import { ServiceItem, isServiceUnavailable } from "./serviceHelpers";

interface selectedServiceProps {
  selectedService: ServiceItem;
  goBackToResults: () => void;
}

const selectedService: FC<selectedServiceProps> = ({
  selectedService,
  goBackToResults,
}) => {
  const { classes } = useConsultFormStyles();
  const isOnVacation = isServiceUnavailable(selectedService.service);
  return (
    <div>
      <div className={classes.spacing}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<ChevronLeftIcon />}
          onClick={goBackToResults}
        >
          Back to Results
        </Button>
      </div>
      <div className={classes.spacing}>
        <ServiceCard
          serviceId={selectedService.id}
          service={selectedService.service}
        />
      </div>
      <div className={classes.spacing}>
        <FormHelperText error>
          {isOnVacation
            ? "Service is not available. Please save as draft and wait for the service to be available again, or select another service."
            : ""}
        </FormHelperText>
      </div>
    </div>
  );
};

export default selectedService;
