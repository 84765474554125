import React from "react";
import LoginGuardedRoute from "src/components/ShowOrRedirectToLogin/ShowOrRedirectToLogin";
import { buildRoute } from "src/routes/internalHelpers";
import Dashboard from "src/views/Dashboard/Dashboard";
import BillingReport from "src/views/Pages/BillingReport";
import Camera from "src/views/Pages/Camera";
import Consult from "src/views/Pages/Consult";
import FormSender from "src/views/Pages/FormSender";
import Gallery from "src/views/Pages/Gallery";
import GetPremiumPage from "src/views/Pages/GetPremiumPage/index";
import ImageAnnotation from "src/views/Pages/ImageAnnotation";
import OneWayMessaging from "src/views/Pages/OneWayMessaging";
import PatientMessages from "src/views/Pages/PatientMessages";
import Profile from "src/views/Pages/Profile";
import References from "src/views/Pages/References";
import EditResourcesPage from "src/views/Pages/Resources/Views/EditResourcesPage";
import SecureMessaging from "src/views/Pages/SecureMessaging";
import MOAConsultInbox from "src/views/Pages/SecureMessaging/MOAConsultInbox";
import EditSchedulePage from "src/views/Pages/SpecialistSchedule/Views/EditSchedulePage";
import EditSpecialistTemplatesPage from "src/views/Pages/SpecialistTemplates/Views/EditSpecialistTemplatesPage";
import ValidationRequired from "src/views/Pages/ValidationRequired";
import dashboardChildrenRoutes, {
  DASHBOARD_ROUTE,
  DashboardChildRouteName,
  DashboardRouteNames,
} from "./dashboardRoutesData";

/**
 * Elements to display for each route
 */
const dashboardRouteElements: {
  [key in DashboardChildRouteName]: React.ReactElement;
} = {
  [DashboardRouteNames.profile]: <Profile />,
  [DashboardRouteNames.camera]: <Camera />,
  [DashboardRouteNames.gallery]: <Gallery />,
  [DashboardRouteNames.imageAnnotation]: <ImageAnnotation />,
  [DashboardRouteNames.billingReport]: <BillingReport />,
  [DashboardRouteNames.forms]: <FormSender />,
  [DashboardRouteNames.oneWayMessaging]: <OneWayMessaging />,
  [DashboardRouteNames.consult]: <Consult />,
  [DashboardRouteNames.secureMessaging]: <SecureMessaging />,
  [DashboardRouteNames.moaConsultInbox]: <MOAConsultInbox />,
  [DashboardRouteNames.patientMessages]: <PatientMessages />,
  [DashboardRouteNames.resources]: <References />,
  [DashboardRouteNames.editResources]: <EditResourcesPage />,
  [DashboardRouteNames.validationRequired]: <ValidationRequired />,
  [DashboardRouteNames.specialistTemplates]: <EditSpecialistTemplatesPage />,
  [DashboardRouteNames.notificationCenter]: <SecureMessaging />,
  [DashboardRouteNames.specialistSchedule]: <EditSchedulePage />,
  [DashboardRouteNames.getPremium]: <GetPremiumPage />,
};

/**
 * Creates the route
 */
const dashboardRoute = buildRoute(
  dashboardChildrenRoutes,
  dashboardRouteElements,
  DashboardRouteNames,
  DASHBOARD_ROUTE,
  <LoginGuardedRoute>
    <Dashboard />
  </LoginGuardedRoute>,
  true,
  DashboardRouteNames.consult,
);

export default dashboardRoute;
