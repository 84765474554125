import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import {
  Button,
  Container,
  Divider,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { type StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { FC, FormEvent, useCallback, useEffect, useState } from "react";
import { fbFunctions } from "src/firebase";
import serverRequest from "src/models/serverRequest";
import { orangeButton } from "src/sharedStyles";
import { makeStyles } from "tss-react/mui";
import ConsultFormCard from "../Consult/ConsultFormCard";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

type PaymentFormProps = {
  isLoading: boolean;
  onLoading: () => void;
  onSuccess: () => void;
  handleError: (message: string) => void;
};

const PaymentForm: FC<PaymentFormProps> = ({
  isLoading,
  onLoading,
  onSuccess,
  handleError,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: FormEvent) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    onLoading();

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      if (submitError.message)
        handleError(`${submitError.type}: ${submitError.message}`);
      else handleError(`Error submitting payment ${submitError.type}`);
      console.error(submitError);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret
    const result = await createPremiumSubscription();
    const { clientSecret } = result.data;

    // Confirm the PaymentIntent using the details collected by the Payment Element
    try {
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${import.meta.env.VITE_PUBLIC_URL}/dashboard/consult`,
        },
      });
      if (error) {
        if (error.message) handleError(`${error.type}: ${error.message}`);
        else handleError(`Error submitting payment ${error.type}`);
        console.error(error);
      }
    } catch (e) {
      console.log(e);
    }
    onSuccess();
  };

  return (
    <ConsultFormCard>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          paddingBottom={2}
        >
          <Grid size={{ xs: 12 }}>
            <PaymentElement options={{ layout: "tabs" }} />
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={!stripe || !elements || isLoading}
              fullWidth
              type="submit"
              sx={{ ...orangeButton }}
            >
              Upgrade to Premium
            </Button>
          </Grid>
        </Grid>
      </form>
    </ConsultFormCard>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  pageContainer: {
    marginTop: theme.spacing(2),
  },
  container: {
    height: "60px",
  },
  error: {
    margin: theme.spacing(0, 2),
  },
}));

const createPremiumSubscription = fbFunctions.httpsCallable(
  "premium-createPremiumSubscription",
);

const getPremiumSubscriptionPrice = fbFunctions.httpsCallable(
  "premium-getPremiumSubscriptionPrice",
);

const GetPremiumPage = () => {
  const { classes } = useStyles();
  const { processState, setProcessState, setProcessErrorMessage } =
    useProcessState({});

  const handleError = useCallback(
    (message: string) => {
      setProcessErrorMessage(message);
      setProcessState(ProcessState.error);
    },
    [processState, ProcessState.error],
  );

  const handlePremiumSignup = async (uid: string) => {
    setProcessState(ProcessState.running);
    try {
      const response = await serverRequest(
        null,
        { "Content-Type": "application/json" },
        JSON.stringify({ uid }),
        "premium-onPremiumSignup",
        true,
      );
      setProcessState(ProcessState.success);
    } catch (error) {
      handleError("Error on signup");
    }
  };

  const [stripeOptions, setStripeOptions] = useState<StripeElementsOptions>({
    mode: "subscription",
    amount: 1500,
    currency: "cad",
  });

  useEffect(() => {
    const fetchPrice = async () => {
      setProcessState(ProcessState.running);
      try {
        const response = await getPremiumSubscriptionPrice();
        setStripeOptions((oldStripeOptions) => ({
          ...oldStripeOptions,
          amount: response.data.amount,
          currency: response.data.currency,
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setProcessState(ProcessState.idle);
      }
    };
    fetchPrice();
  }, [getPremiumSubscriptionPrice, setStripeOptions]);

  return (
    <Container className={classes.pageContainer}>
      <Grid container direction="column" spacing={3}>
        <Grid container direction="column" spacing={1}>
          <Grid>
            <Typography variant="h5">Upgrade to Alethea Premium</Typography>
          </Grid>
          <Grid>
            <Divider />
          </Grid>
          <Grid>
            <Typography>
              Alethea Premium is available through a $15/month subscription that
              offers 30 secure one-way messages or forms for you to send to your
              patients each month. There are no billing services included in
              this upgrade. You will need to bill for any communications sent
              through Alethea.
              <br />
              <br />
              Please enter your credit card information below to upgrade your
              account to Alethea Premium. You will be billed monthly for your
              subscription. If you would like more information regarding the
              benefits of Alethea Premium, please contact{" "}
              <a href="mailto:sales@aletheamedical.com">
                sales@aletheamedical.com{" "}
              </a>
              for more information.
            </Typography>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Elements stripe={stripePromise} options={stripeOptions}>
            <PaymentForm
              handleError={handleError}
              isLoading={processState === ProcessState.running}
              onLoading={() => setProcessState(ProcessState.running)}
              onSuccess={() => setProcessState(ProcessState.success)}
            />
          </Elements>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GetPremiumPage;
